import * as React from "react"
const Choco = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAvklEQVR4nO2UPQoCMRCFv8JjuKDHsPIKWuQiVlbewCOslSLkIHoQb6ClxcrABBLIJKu7aOODD0Lm5W1+F76gNXAGfAXxrPoEPoCL0ungm+K1L9TvfQJlgFOkTTQrq15U9yZViWmvhNlcFZep/yawG3vJbsxDccBUkbZooVh1U0309Zh5xrvUWmPHwcnYpyewAybq20S1YykwvAQLOemtvqbQ54cE5vD/wESHD/awTSNSyX0TQ+3nGhDvrBQ4WC9Bdq0/Df5xHAAAAABJRU5ErkJggg=="
      width={20}
      height={20}
    />
  </svg>
)
export default Choco
