import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LocationIcon from "../../components/Svg/LocationIcon/LocationIcon";
import Clock from "../../components/Svg/Clock/Clock";
import Pen from "../../components/Svg/Pen/Pen";
import Info from "../../components/Svg/Info/Info";
import Twitter from "../../components/Svg/Twitter/Twitter";
import Facebook from "../../components/Svg/Facebook/Facebook";
import Instagram from "../../components/Svg/Instagram/Instagram";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./index.scss";
import LocationPin from "../../components/Svg/LocationPin/LocationPin";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  activeBranch,
  disableBranch,
  storeBranchId,
  storeData,
  storeLandingData,
} from "../../features/dataSlice";
import { useParams } from "react-router-dom";
import { LandingPage_Data, Main_Api_Link } from "../../constants/ApiConstants";
import axios from "axios";
import Loader from "../../components/Loader";
import Linkedin from "../../components/Svg/Lindedin/Linkedin";
import Youtube from "../../components/Svg/Youtube/Youtube";
import Snapchat from "../../components/Svg/Snapchat/Snapchat";
import Star from "../../components/Svg/Star/Star";
import { useTranslation } from "react-i18next";
//! for demo only
import { coverImages, images } from "../../assets";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import ImgLoader from "../../components/ImgLoader/ImgLoader";
//? for demo only

//! for demo purposes only

const getRandomCoverImage = () => {
  const keys = Object.keys(coverImages);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return images[randomKey];
};

const getRandomMenuImage = () => {
  const keys = Object.keys(images);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return images[randomKey];
};

//? for demo purposes only

function LandingPage() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  // other constance
  let { userId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
    setRoute(`/menu/${userId}`);
    //
  }, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lang, setLang] = useState("");
  const [branch, setBranch] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const handleOpenLocationModal = () => setOpenLocationModal(true);
  const handleCloseLocationModal = () => setOpenLocationModal(false);
  const [openWorkingHoursModal, setOpenWorkingHoursModal] = useState(false);
  const handleOpenWorkingHoursModal = () => setOpenWorkingHoursModal(true);
  const handleCloseWorkingHoursModal = () => setOpenWorkingHoursModal(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const handleOpenInfoModal = () => setOpenInfoModal(true);
  const handleCloseInfoModal = () => setOpenInfoModal(false);
  const [activeBtn, setActiveBtn] = useState(1);
  const [route, setRoute] = useState("");
  const [isError, setIsError] = useState(false);
  const HandleChangeBtns = (value) => {
    setActiveBtn(value);
  };

  const getData = () => {
    setIsError(false)
    const url = process.env.REACT_APP_API_URL + LandingPage_Data + userId;
    // const url = Main_Api_Link + LandingPage_Data + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setData(res.data);
        document.title = `Menu ${res.data.name}`;
        dispatch(storeLandingData(res.data));
        changeLanguage(res.data.currentLanguage);
      })
      .then(() => {
        setLoading(false);
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        setIsError(true)
        setLoading(false)
      });
  };

  const CustomButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    width: "100%",
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: data?.mainColor ? data.mainColor : "84cec3",
    borderColor: data?.mainColor ? data.mainColor : "84cec3",
    fontFamily: "Roboto",
    "&:hover": {
      backgroundColor: data?.mainColor ? data.mainColor : "84cec3",
      borderColor: data?.mainColor ? data.mainColor : "84cec3",
      boxShadow: "none",
      color: "black",
    },
    "&:active": {
      backgroundColor: data?.mainColor ? data.mainColor : "84cec3",
      borderColor: data?.mainColor ? data.mainColor : "84cec3",
      boxShadow: "none",
      color: "black",
    },
  });

  const handleChange1 = (event) => {
    setLang(event.target.value);
    changeLanguage(event.target.value);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleChange2 = (event) => {
    setBranch(event.target.value);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const handleDeactiveBranch = () => {
    dispatch(disableBranch());
    setRoute(`/menu/${userId}`);
  };

  const handleBranchSelection = (branch) => {
    dispatch(activeBranch());
    dispatch(storeBranchId(branch.id));
    setRoute(`/menu/${userId}/${branch.id}`);
  };

  return (
    <>
      {loading ? (
        <>
          <ImgLoader />
        </>
      ) : (
        <>
          {isError ? (
            <>
            <ErrorPage />
            </>
          ) : (
            <>
              <Box sx={{ flexGrow: 1 }}>
                {/* location modal */}
                <Modal
                  open={openLocationModal}
                  onClose={handleCloseLocationModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <h3 className="locationHeader">{t("ourSites")} </h3>
                    <hr />
                    {data?.branches?.map((branch) => (
                      <div key={branch.id} className="LocationModalLine">
                        <h4 className="branchNameIntab">{branch.name}</h4>
                        <div
                          className="LocationPin"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                          // style={{ color: data.mainColor }}
                          onClick={() => window.open(branch.location, "_blank")}
                        >
                          <LocationPin />
                        </div>
                      </div>
                    ))}
                  </Box>
                </Modal>
                {/* workingHours Modal */}
                <Modal
                  open={openWorkingHoursModal}
                  onClose={handleCloseWorkingHoursModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <h3 className="locationHeaderCenter">
                      {t("workinghours")}{" "}
                    </h3>
                    <hr />
                    {data?.workingHours.map((work) => (
                      <div key={work.id} className="LocationModalLine">
                        <p>{work.hours}</p>
                        <p>: {work.day}</p>
                      </div>
                    ))}
                  </Box>
                </Modal>
                {/* Info Modal */}
                <Modal
                  open={openInfoModal}
                  onClose={handleCloseInfoModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    {data.show_background_image === 1 ? (
                      <img
                        className="logosmall"
                        // src={getRandomMenuImage()}
                        src={data?.img}
                        // src={"https://www.gifpng.com/100x100/333333/ffffff"}
                        alt="Logosm"
                      />
                    ) : (
                      ""
                    )}

                    <h3>{data.name}</h3>
                    <p>{data.desc}</p>
                    <hr />
                    <div className="btnsWrapper">
                      <p
                        onClick={() => HandleChangeBtns(2)}
                        style={{
                          backgroundColor:
                            activeBtn === 2
                              ? data.mainColor
                                ? data.mainColor
                                : "#84cec3"
                              : "",
                          color:
                            activeBtn === 2
                              ? "#fff"
                              : data.mainColor
                              ? data.mainColor
                              : "#84cec3",
                        }}
                        className="btnsWrapperItem"
                      >
                        {t("aboutUs")}
                      </p>
                      <p
                        onClick={() => HandleChangeBtns(1)}
                        style={{
                          backgroundColor:
                            activeBtn === 1
                              ? data.mainColor
                                ? data.mainColor
                                : "#84cec3"
                              : "",
                          color:
                            activeBtn === 1
                              ? "#fff"
                              : data.mainColor
                              ? data.mainColor
                              : "#84cec3",
                        }}
                        className="btnsWrapperItem"
                      >
                        {t("rates")}
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {activeBtn === 1 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            // backgroundColor:"red"
                          }}
                        >
                          <p>{t("rateAvg")} </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {Array(data.restaurantAvgRating)
                              .fill(0)
                              .map((_, i) => (
                                <Star key={i} />
                              ))}
                          </div>

                          <Link
                            to={`/restaurantReviews/${data.id}`}
                            className="btnClick"
                          >
                            {t("showAllRate")}
                          </Link>
                        </div>
                      ) : (
                        <>
                          {data?.can_show_phone === 1 && (
                            <>
                              <h5>{t("phoneNumber")}</h5>
                              <p>{data?.phone}</p>

                              <hr />
                            </>
                          )}
                          {data?.showLocation === 1 && (
                            <>
                              <h5>{t("address")}</h5>
                              <p>{data?.address}</p>
                              <hr />
                            </>
                          )}
                          <h5>{t("socialSites")}</h5>
                          <div className="socialBtns2">
                            {data.twitterAccountLink !== null && (
                              <div
                                onClick={() =>
                                  window.open(data.twitterAccountLink, "_blank")
                                }
                                className="icon3"
                                style={
                                  data?.mainColor
                                    ? { color: data.mainColor }
                                    : { color: "#84cec3" }
                                }
                              >
                                <Twitter />
                              </div>
                            )}
                            {data.InstagramAccountLink !== null && (
                              <div
                                onClick={() =>
                                  window.open(
                                    data.InstagramAccountLink,
                                    "_blank"
                                  )
                                }
                                className="icon3"
                                style={
                                  data?.mainColor
                                    ? { color: data.mainColor }
                                    : { color: "#84cec3" }
                                }
                              >
                                <Instagram />
                              </div>
                            )}
                            {data.facebookAccountLink !== null && (
                              <div
                                onClick={() =>
                                  window.open(
                                    data.facebookAccountLink,
                                    "_blank"
                                  )
                                }
                                className="icon3"
                                style={
                                  data?.mainColor
                                    ? { color: data.mainColor }
                                    : { color: "#84cec3" }
                                }
                              >
                                <Facebook />
                              </div>
                            )}
                            {data.linkedinAccountLink !== null && (
                              <div
                                onClick={() =>
                                  window.open(
                                    data.linkedinAccountLink,
                                    "_blank"
                                  )
                                }
                                className="icon3"
                                style={
                                  data?.mainColor
                                    ? { color: data.mainColor }
                                    : { color: "#84cec3" }
                                }
                              >
                                <Linkedin />
                              </div>
                            )}
                            {data.youtubeAccountLink !== null && (
                              <div
                                onClick={() =>
                                  window.open(data.youtubeAccountLink, "_blank")
                                }
                                className="icon3"
                                style={
                                  data?.mainColor
                                    ? { color: data.mainColor }
                                    : { color: "#84cec3" }
                                }
                              >
                                <Youtube />
                              </div>
                            )}
                            {data.snapchatAccountLink !== null && (
                              <div
                                onClick={() =>
                                  window.open(
                                    data.snapchatAccountLink,
                                    "_blank"
                                  )
                                }
                                className="icon3"
                                style={
                                  data?.mainColor
                                    ? { color: data.mainColor }
                                    : { color: "#84cec3" }
                                }
                              >
                                <Snapchat />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Box>
                </Modal>
                <Grid container spacing={2}>
                  <Grid
                    sm={4}
                    xs={12}
                    className="LeftLanding"
                    style={{
                      background: data?.background
                        ? data.background
                        : "#84cec3",
                      borderRight: `3px solid ${
                        data.mainColor ? data.mainColor : "#84cec3"
                      }`,
                    }}
                  >
                    <Stack
                      marginRight={3}
                      direction="row"
                      justifyContent={"flex-end"}
                      spacing={3}
                    >
                      <div
                        className="icon"
                        style={
                          data?.mainColor
                            ? { color: data.mainColor }
                            : { color: "#84cec3" }
                        }
                        onClick={handleOpenInfoModal}
                      >
                        <Info />
                      </div>
                      <Link
                        to={`/rating/create/${data.id}`}
                        className="icon"
                        style={
                          data?.mainColor
                            ? { color: data.mainColor }
                            : { color: "#84cec3" }
                        }
                      >
                        <Pen />
                      </Link>
                      {data.showHours === 1 && (
                        <div
                          className="icon"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                          onClick={handleOpenWorkingHoursModal}
                        >
                          <Clock />
                        </div>
                      )}
                      {data.showLocation === 1 && (
                        <div
                          className="icon1"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                          onClick={handleOpenLocationModal}
                        >
                          <LocationIcon />
                        </div>
                      )}
                    </Stack>

                    <div className="secondContainer">
                      <div className="logoimg">
                        {data.showImageOnLeft === 1 ? (
                          <img
                            className="logo"
                            //! for demo
                            // src={getRandomMenuImage()}
                            src={data?.img}
                            // src={"https://www.gifpng.com/160x160/333333/ffffff"}
                            alt="Logo"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>

                      {data.showName === 1 ? (
                        <>
                          <h3
                            className="header"
                            style={{ color: data.textColor }}
                          >
                            {data.name}
                          </h3>
                        </>
                      ) : (
                        <div style={{ marginTop: "0rem" }}></div>
                      )}
                      <h5 className="header2" style={{ color: data.textColor }}>
                        {t("welcomeTo")} {data.name}
                      </h5>
                      {data.showLanguagesSelector == true && (
                        <div className="startForm">
                          <label
                            style={{ color: data.textColor }}
                            className="label"
                          >
                            {t("chooseLng")}
                          </label>
                          <FormControl
                            sx={{
                              marginTop: 2,
                              minWidth: 180,
                              borderRadius: 30,
                            }}
                          >
                            <InputLabel>{t("lang")}</InputLabel>
                            <Select
                              sx={{ borderRadius: 30, height: 50 }}
                              open={open1}
                              onClose={handleClose1}
                              onOpen={handleOpen1}
                              value={lang}
                              label={t("lang")}
                              onChange={handleChange1}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={"ar"}>{t("arabic")}</MenuItem>
                              <MenuItem value={"en"}>{t("english")}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      )}

                      <div className="startForm">
                        <label
                          style={{ color: data.textColor }}
                          className="label"
                        >
                          {t("branchChoose")}
                        </label>
                        <FormControl
                          sx={{ marginTop: 2, minWidth: 180, borderRadius: 30 }}
                        >
                          <InputLabel>{t("branches")}</InputLabel>
                          <Select
                            sx={{ borderRadius: 30, height: 50 }}
                            open={open2}
                            onClose={handleClose2}
                            onOpen={handleOpen2}
                            value={branch}
                            label={t("branches")}
                            onChange={handleChange2}
                          >
                            <MenuItem
                              onClick={() => handleDeactiveBranch()}
                              value="المطعم الرئيسي"
                            >
                              <em>{t("mainResturant")}</em>
                            </MenuItem>
                            {data?.branches?.map((branch) => (
                              <MenuItem
                                onClick={() => handleBranchSelection(branch)}
                                key={branch.id}
                                value={branch.id}
                              >
                                {branch.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <Link
                          className="dynamicLink"
                          // to={`/menu/${data.id}`}
                          to={route}
                          // state={{ data: data }}
                        >
                          <CustomButton
                            className="btn1"
                            variant="contained"
                            sx={{
                              background: data.mainColor
                                ? data.mainColor
                                : "#84cec3",
                              borderRadius: 50,
                              marginTop: 2,
                              padding: 1,
                              fontSize: 18,
                              fontFamily: "Roboto",
                              paddingRight: 3,
                              paddingLeft: 3,
                            }}
                          >
                            {t("clickToContinue")}
                          </CustomButton>
                        </Link>

                        <Link
                          className="dynamicLink"
                          to={`/parties/${data.id}`}
                          state={{ data: data }}
                        >
                          <CustomButton
                            className="btn1"
                            variant="contained"
                            sx={{
                              background: data.mainColor
                                ? data.mainColor
                                : "#84cec3",
                              borderRadius: 50,
                              marginTop: 2,
                              padding: 1,
                              fontSize: 18,
                              fontFamily: "Roboto",
                              paddingRight: 3,
                              paddingLeft: 3,
                            }}
                          >
                            {t("partiesApp")}
                          </CustomButton>
                        </Link>

                        <div className="socialBtns">
                          {data.twitterAccountLink !== null && (
                            <div
                              onClick={() =>
                                window.open(data.twitterAccountLink, "_blank")
                              }
                              className="icon"
                              style={
                                data?.mainColor
                                  ? { color: data.mainColor }
                                  : { color: "#84cec3" }
                              }
                            >
                              <Twitter />
                            </div>
                          )}
                          {data.InstagramAccountLink !== null && (
                            <div
                              onClick={() =>
                                window.open(data.InstagramAccountLink, "_blank")
                              }
                              className="icon"
                              style={
                                data?.mainColor
                                  ? { color: data.mainColor }
                                  : { color: "#84cec3" }
                              }
                            >
                              <Instagram />
                            </div>
                          )}
                          {data.facebookAccountLink !== null && (
                            <div
                              onClick={() =>
                                window.open(data.facebookAccountLink, "_blank")
                              }
                              className="icon"
                              style={
                                data?.mainColor
                                  ? { color: data.mainColor }
                                  : { color: "#84cec3" }
                              }
                            >
                              <Facebook />
                            </div>
                          )}
                          {data.linkedinAccountLink !== null && (
                            <div
                              onClick={() =>
                                window.open(data.linkedinAccountLink, "_blank")
                              }
                              className="icon"
                              style={
                                data?.mainColor
                                  ? { color: data.mainColor }
                                  : { color: "#84cec3" }
                              }
                            >
                              <Linkedin />
                            </div>
                          )}
                          {data.youtubeAccountLink !== null && (
                            <div
                              onClick={() =>
                                window.open(data.youtubeAccountLink, "_blank")
                              }
                              className="icon"
                              style={
                                data?.mainColor
                                  ? { color: data.mainColor }
                                  : { color: "#84cec3" }
                              }
                            >
                              <Youtube />
                            </div>
                          )}
                          {data.snapchatAccountLink !== null && (
                            <div
                              onClick={() =>
                                window.open(data.snapchatAccountLink, "_blank")
                              }
                              className="icon"
                              style={
                                data?.mainColor
                                  ? { color: data.mainColor }
                                  : { color: "#84cec3" }
                              }
                            >
                              <Snapchat />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className="rightLanding"
                    display={{ xs: "none", sm: "flex" }}
                  >
                    <img
                      className="logo"
                      // src={getRandomMenuImage()}
                      src={data?.img}
                      // src={"https://www.gifpng.com/160x160/333333/ffffff"}
                      alt="BigLogo"
                    />
                    <div
                      style={{
                        borderTop: `3px solid ${
                          data.mainColor ? data.mainColor : "#84cec3"
                        }`,
                        marginTop: "1rem",
                        padding: "0 5rem",
                      }}
                    >
                      <h3
                        className="lastHead"
                        style={
                          data?.mainColor
                            ? { color: data.mainColor }
                            : { color: "#84cec3" }
                        }
                      >
                        {data.name}
                      </h3>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}

export default LandingPage;
