import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { useParams, useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@mui/material/FormControl";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Main_Api_Link } from "../../../constants/ApiConstants";
import useButtonLoader from "./useButtonLoader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const RatingSwal = withReactContent(Swal);

const CustomerForm = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  let { restaurantId, branchNumber } = useParams();
  const location = useLocation();
  const { data } = location.state?.data || {};
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [titleError, setTitleError] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [customerNameError, setCustomerNameError] = useState("");
  const [customerPhoneError, setCustomerPhoneError] = useState("");
  const [element, setLoading] = useButtonLoader(
    i18n.t("sendRate"),
    i18n.t("sending")
  );
  const menuId = useSelector((state) => state.dataReducer.landingData.id);
  const branchId = useSelector((state) => state.dataReducer.branchId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (!title) {
      setTitleError(i18n.t("pleaseEnterAddress"));
      hasError = true;
    } else {
      setTitleError("");
    }

    if (!body) {
      setBodyError(i18n.t("pleaseEnterRatingContent"));
      hasError = true;
    } else {
      setBodyError("");
    }

    if (!customerName.match(/^[a-zA-Z\u0600-\u06FF\s]+$/)) {
      setCustomerNameError(i18n.t("plsEnterValidName"));
      hasError = true;
    } else {
      setCustomerNameError("");
    }

    if (!customerPhone.match(/^\d{10,}$/)) {
      setCustomerPhoneError(i18n.t("plsEnterValidPhone"));
      hasError = true;
    } else {
      setCustomerPhoneError("");
    }

    if (!hasError) {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reviews/store-content`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title,
            body,
            customer_name: customerName,
            customer_phone: customerPhone,
            restaurant_id: menuId ? menuId : restaurantId,
          }),
        });

        if (response.ok) {
          toast.success(i18n.t("ratingSent"));
          setTimeout(() => {
            window.location.href = `/menu/${menuId ? menuId : restaurantId}/${
              branchId ? branchId : branchNumber ? branchNumber : ""
            }`;
          }, 2000);
        } else {
          RatingSwal.fire({
            icon: "error",
            title: i18n.t("processFailure"),
            text: i18n.t("youDidntRate"),
          });
        }
      } catch (error) {
        console.error(error);
        RatingSwal.fire({
          icon: "error",
          title: i18n.t("processFailure"),
          text: i18n.t("youDidntRate"),
        });
      }
    }



    // if (!hasError) {
    //   setLoading(true);
    //   try {
    //     const response = await fetch(`${Main_Api_Link}/reviews/store-content`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         title,
    //         body,
    //         customer_name: customerName,
    //         customer_phone: customerPhone,
    //         restaurant_id: menuId ? menuId : restaurantId,
    //       }),
    //     });

    //     if (response.ok) {
    //       toast.success(i18n.t("ratingSent"));
    //       setTimeout(() => {
    //         window.location.href = `/menu/${menuId ? menuId : restaurantId}/${
    //           branchId ? branchId : branchNumber ? branchNumber : ""
    //         }`;
    //       }, 2000);
    //     } else {
    //       RatingSwal.fire({
    //         icon: "error",
    //         title: i18n.t("processFailure"),
    //         text: i18n.t("youDidntRate"),
    //       });
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     RatingSwal.fire({
    //       icon: "error",
    //       title: i18n.t("processFailure"),
    //       text: i18n.t("youDidntRate"),
    //     });
    //   }
    // }
  };

  return (
    <>
      <Navbar data={data} />
      <div className="headerNav"></div>
      <div className="RatingWrapper">
        <div className="form">
          <h2 style={{ marginBottom: "3vw", marginTop: "3vw" }}>
            {t("customerReview")}
          </h2>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              width={{ md: 1000, sm: 500, xs: 300 }}
              style={{ paddingBottom: "4vw" }}
            >
              <Grid md={20} sm={20} xs={20}>
                <FormControl
                  className="formcontrol"
                  variant="standard"
                  style={{ marginBottom: "2.4vw", width: "30vw" }}
                >
                  <TextField
                    id="title-input"
                    label={t("address")}
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {titleError && (
                    <span style={{ color: "red" }}>{titleError}</span>
                  )}
                </FormControl>
                <br />
                <FormControl
                  className="formcontrol"
                  variant="standard"
                  style={{ marginBottom: "2.4vw", width: "30vw" }}
                >
                  <TextField
                    label={t("content")}
                    variant="outlined"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  ></TextField>
                  {bodyError && (
                    <span style={{ color: "red" }}>{bodyError}</span>
                  )}
                </FormControl>
                <br />

                <div>
                  <FormControl
                    className="formcontrol"
                    variant="standard"
                    style={{ marginBottom: "2.4vw", width: "30vw" }}
                  >
                    <TextField
                      id="customer_name-input"
                      label={t("customerName")}
                      variant="outlined"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                    {customerNameError && (
                      <span style={{ color: "red" }}>{customerNameError}</span>
                    )}
                  </FormControl>
                </div>
                <br />

                <FormControl
                  style={{ marginBottom: "2.4vw" }}
                  className="phoneinput-wrapper"
                >
                  <PhoneInput
                    country={"sa"}
                    onlyCountries={["us", "eg", "sa", "ae"]}
                    id="phone-input"
                    value={customerPhone}
                    onChange={setCustomerPhone}
                  />
                  {customerPhoneError && (
                    <span style={{ color: "red" }}>{customerPhoneError}</span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              ref={element}
            >
              {t("sendYourOpinion")}
            </Button>
          </form>
          <Footer />
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default CustomerForm;
