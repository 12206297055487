import React from "react";
import Lottie from "react-lottie";
import animation from "../../animations/animation_lk6lrgiw.json";
import { Button } from "@mui/material";
import "./index.scss";

const ErrorPage = () => {
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: animation
  };

  const handleGoBack = () => {
    console.log("Go back")
  };

  return (
    <div className="error-page">
      <Lottie
        width={750}
        options={defaultOptions}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
      >
        Go Back
      </Button>
    </div>
  );
};

export default ErrorPage;
