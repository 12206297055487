import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './assets/locales/en.json';
import arTranslation from './assets/locales/ar.json';

const fallbackLng = ['ar']; // Set Arabic as the default language
const availableLanguages = ['en', 'ar'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
    },
  });

export default i18n;
