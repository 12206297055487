import React from 'react'

const LocationIcon = () => {
  return (
    <svg
    className="svg-inline--fa fa-location-pin"
    aria-hidden="true"
    data-prefix="fas"
    data-icon="location-pin"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M384 192c0 87.4-117 243-168.3 307.2-12.3 15.3-35.1 15.3-47.4 0C116.1 435 0 279.4 0 192 0 85.96 85.96 0 192 0c106 0 192 85.96 192 192z"
    />
  </svg>
  )
}

export default LocationIcon