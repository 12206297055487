import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import Grid from "@mui/material/Unstable_Grid2";
import "./index.scss";
import Button from "@mui/material/Button";
import Footer from "../../../components/Footer/Footer";
import Rating1 from "@mui/material/Rating";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Main_Api_Link } from "../../../constants/ApiConstants";
import useButtonLoader from "./useButtonLoader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const RatingSwal = withReactContent(Swal);

const Rating = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
 
  let { restaurantId, branchNumber } = useParams();
  const location = useLocation();
  const { data } = location.state?.data || {};
  const [serviceRate, setServiceRate] = useState(null);
  const [foodRate, setFoodRate] = useState(null);
  const [overallRate, setOverallRate] = useState(null);
  const [serviceRateError, setServiceRateError] = useState("");
  const [foodRateError, setFoodRateError] = useState("");
  const [overallRateError, setOverallRateError] = useState("");
  const [element, setLoading] = useButtonLoader(
    i18n.t("sendRate"),
    i18n.t("sending")
  );
  const navigate = useNavigate();

  const branchId = useSelector((state) => state.dataReducer.branchId);
  const menuId = useSelector((state) => state.dataReducer.landingData.id);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasError = false;
    const createdAt = new Date().toISOString();
    // Validate fields
    if (!serviceRate) {
      setServiceRateError(i18n.t("pleaseEnterServiceRate"));
      hasError = true;
    } else {
      setServiceRateError("");
    }
    if (!foodRate) {
      setFoodRateError(i18n.t("pleaseEnterFoodRate"));
      hasError = true;
    } else {
      setFoodRateError("");
    }
    if (!overallRate) {
      setOverallRateError(i18n.t("pleaseEnterOverAllRate"));
      hasError = true;
    } else {
      setOverallRateError("");
    }
    if (!hasError) {
      setLoading(true);
      // try {
      //   const response = await fetch(`${Main_Api_Link}/reviews/store-stars`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       serviceRate,
      //       foodRate,
      //       overallRate,
      //       restaurantId: menuId ? menuId : restaurantId,
      //       createdAt,
      //     }),
      //   });

      //   if (response.ok) {
      //     toast.success(i18n.t("pleaseContinueRate"));
      //     setTimeout(() => {
      //       window.location.href = `/review/create/${
      //         menuId ? menuId : restaurantId
      //       }/${branchId ? branchId : branchNumber ? branchNumber : ""}`;
      //     }, 2000);
      //   } else {
      //     RatingSwal.fire({
      //       icon: "error",
      //       title: i18n.t("operationFailure"),
      //       text: i18n.t("youDidntRate"),
      //     });
      //   }
      // } catch (error) {
      //   RatingSwal.fire({
      //     icon: "error",
      //     title: i18n.t("operationFailure"),
      //     text: i18n.t("youDidntRate"),
      //   });
      // }




      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reviews/store-stars`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            serviceRate,
            foodRate,
            overallRate,
            restaurantId: menuId ? menuId : restaurantId,
            createdAt,
          }),
        });

        if (response.ok) {
          toast.success(i18n.t("pleaseContinueRate"));
          setTimeout(() => {
            window.location.href = `/review/create/${
              menuId ? menuId : restaurantId
            }/${branchId ? branchId : branchNumber ? branchNumber : ""}`;
          }, 2000);
        } else {
          RatingSwal.fire({
            icon: "error",
            title: i18n.t("operationFailure"),
            text: i18n.t("youDidntRate"),
          });
        }
      } catch (error) {
        RatingSwal.fire({
          icon: "error",
          title: i18n.t("operationFailure"),
          text: i18n.t("youDidntRate"),
        });
      }
    }
  };

  return (
    <>
      <Navbar data={data} />
      <div className="headerNav"></div>
      <div className="RatingWrapper">
        <div className="form">
          <h3>{t("resturantRate")}</h3>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              width={{ md: 1000, sm: 500, xs: 300 }}
              style={{ paddingBottom: "4vw" }}
            >
              <Grid md={12} sm={12} xs={12}>
                <div className="ratingcontainer">
                  <h5>{t("serviceRate")}</h5>
                  <h5>{serviceRate} / 5</h5>
                  <Rating1
                    size="large"
                    name="simple-controlled"
                    value={serviceRate}
                    onChange={(event, newValue) => {
                      setServiceRate(newValue);
                    }}
                    required
                  />
                  {serviceRateError && (
                    <div
                      className="error-message"
                      style={{ color: "red", fontWeight: "900" }}
                    >
                      {serviceRateError}
                    </div>
                  )}
                  <div className="ratingcontainerline">
                    <hr />
                  </div>
                </div>
                <div className="ratingcontainer">
                  <h5>{t("foodRate")}</h5>
                  <h5>{foodRate} / 5</h5>
                  <Rating1
                    size="large"
                    name="simple-controlled"
                    value={foodRate}
                    onChange={(event, newValue) => {
                      setFoodRate(newValue);
                    }}
                    required
                  />
                  {foodRateError && (
                    <div
                      className="error-message"
                      style={{ color: "red", fontWeight: "900" }}
                    >
                      {foodRateError}
                    </div>
                  )}
                  <div className="ratingcontainerline">
                    <hr />
                  </div>
                </div>
                <div className="ratingcontainer">
                  <h5>{t("overalllRate")}</h5>
                  <h5>{overallRate} / 5</h5>
                  <Rating1
                    size="large"
                    name="simple-controlled"
                    value={overallRate}
                    onChange={(event, newValue) => {
                      setOverallRate(newValue);
                    }}
                    required
                  />
                  {overallRateError && (
                    <div
                      className="error-message"
                      style={{ color: "red", fontWeight: "900" }}
                    >
                      {overallRateError}
                    </div>
                  )}
                  <div className="ratingcontainerline">
                    <hr />
                  </div>
                  <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Button
                        sx={{
                          width: "100%",
                          marginBottom: { xs: 2, sm: 0 },
                          marginRight: { xs: 0, sm: 2 },
                        }}
                        variant="outlined"
                        color="error"
                        size="large"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        {t("getBack")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        sx={{
                          width: "100%",
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="large"
                        ref={element}
                      >
                        {t("sendRate")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-right" autoClose={1000} hideProgressBar />
    </>
  );
};

export default Rating;
