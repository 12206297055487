import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  landingData: {},
  branchId: null,
  isBranch: false,
  userId: null,
  toggleDrawer: false,
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    storeData: (state, action) => {
      state.data = action.payload;
    },

    RemoveData: (state) => {
      state.date = null;
    },
    storeLandingData: (state, action) => {
      state.landingData = action.payload;
    },

    removeLandingData: (state) => {
      state.landingData = null;
    },
    storeBranchId: (state, action) => {
      state.branchId = action.payload;
    },
    activeBranch: (state, action) => {
      state.isBranch = true;
    },
    disableBranch: (state, action) => {
      state.isBranch = false;
    },
    storeUserId: (state, action) => {
      state.userId = action.payload;
    },
    openDrawer: (state) => {
      state.toggleDrawer = true;
    },
    closeDrawer: (state) => {
      state.toggleDrawer = false;
    },
  },
});

export const {
  storeBranchId,
  storeData,
  RemoveData,
  storeLandingData,
  removeLandingData,
  activeBranch,
  disableBranch,
  storeUserId,
  openDrawer,
  closeDrawer,
} = dataSlice.actions;
export default dataSlice.reducer;
