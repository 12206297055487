import React from "react";
import Lottie from "react-lottie";
import animationData from "./../animations/99844-loading.json";


class Loader extends React.Component {
  defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: animationData
  };

  render() {
    return (
      <Lottie
        width={150}
        style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}
        options={this.defaultOptions}
      />
    );
  }
}

export default Loader