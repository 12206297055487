import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import "./index.scss";
import PhoneInput from "react-phone-input-2";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Footer from "../../../components/Footer/Footer";
import TextField from '@material-ui/core/TextField';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Main_Api_Link } from "../../../constants/ApiConstants";
import useButtonLoader from "../Rating/useButtonLoader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const Reservation = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [time, setTime] = useState("");
  const [timeError, setTimeError] = useState("");
  const [formError, setFormError] = useState("");
  const { restaurantId, branchNumber } = useParams();
  const [personCount, setPersonCount] = useState(1);
  const [element, setLoading] = useButtonLoader(i18n.t("TableResrve"), i18n.t("reservingTable"));
  let hasError = false;


  const branchId = useSelector((state) => state.dataReducer.branchId);
  const menuId = useSelector(state=>state.dataReducer.landingData.id)


  const increment = () => {
    setPersonCount(personCount + 1);
  };
  const decrement = () => {
    if (personCount > 1) {
      setPersonCount(personCount - 1);
    }
  };
  // Validation function
const validateDateTime = () => {
  // Get the current date and time
  const now = new Date();

  // Convert the selected date and time to a Date object
  const selectedDateTime = new Date(`${date}T${time}`);

  // Validate Date
  if (!date) {
    setDateError(i18n.t("plsEnterValidAppointment"));
    hasError = true;
  } else {
    if (selectedDateTime < now) {
      setDateError(i18n.t("pleaseEnterValidDateAndTime"));
      setTimeError(i18n.t("pleaseEnterValidDateAndTime"));
      hasError = true;
    } else {
      setDateError("");
      setTimeError("");
    }
  }

  // Validate Time
  if (!time) {
    setTimeError(i18n.t("pleaseEnterValidTime"));
    hasError = true;
  } else {
    if (selectedDateTime < now) {
      setDateError(i18n.t("pleaseEnterValidDateAndTime"));
      setTimeError(i18n.t("pleaseEnterValidDateAndTime"));
      hasError = true;
    } else {
      setDateError("");
      setTimeError("");
    }
  }
};
  const handleSubmit = async(event) => {
    event.preventDefault();
  
    // Validate name
    if (!name.match(/^[a-zA-Z\u0600-\u06FF\s]+$/)) {
      setNameError(i18n.t("plsEnterValidName"));
      hasError = true;
    } else {
      setNameError("");
    }
  
    // Validate email
    if (!email.match(/^\S+@\S+\.\S+$/)) {
      setEmailError(i18n.t("plsEnterValidEmail"));
      hasError = true;
    } else {
      setEmailError("");
    }

    // Validate Phone number 
    if (!phone.match(/^\d{10,}$/)) {
      setPhoneError(i18n.t("plsEnterValidPhone"));
      hasError = true;
    } else {
      setPhoneError("");
    }

      // Validate Date
      validateDateTime();

    if (!name || !phone || !email || !date) {
      setFormError(i18n.t("allFieldsIsRequired"));
      hasError = true;
    } else {
      setFormError("");
    }
  
  if (!hasError) {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/submitTable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          phone_number:phone,
          email,
          date,
          restaurant_id:menuId? menuId: restaurantId,
          quantity:personCount,
          time:time,
          branch:branchId? branchId: branchNumber? branchNumber: null
        })
      }
      
      );

      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: i18n.t("OperationSucess"),
          text: i18n.t("PartySucess")
        })
        setTimeout(()=>{
          window.location.href = `/menu/${menuId? menuId: restaurantId}/${branchId ? branchId : branchNumber? branchNumber : ""}`;
        }, 3000)
      } else {
        MySwal.fire({
          icon: 'error',
          title: i18n.t("processFailure"),
          text: i18n.t("youDidntRate")
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: i18n.t("processFailure"),
        text: i18n.t("youDidntRate")
      });
    }
  }




  // if (!hasError) {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${Main_Api_Link}/submitTable`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         name,
  //         phone_number:phone,
  //         email,
  //         date,
  //         restaurant_id:menuId? menuId: restaurantId,
  //         quantity:personCount,
  //         time:time,
  //         branch:branchId? branchId: branchNumber? branchNumber: null
  //       })
  //     }
      
  //     );

  //     if (response.ok) {
  //       MySwal.fire({
  //         icon: 'success',
  //         title: i18n.t("OperationSucess"),
  //         text: i18n.t("PartySucess")
  //       })
  //       setTimeout(()=>{
  //         window.location.href = `/menu/${menuId? menuId: restaurantId}/${branchId ? branchId : branchNumber? branchNumber : ""}`;
  //       }, 3000)
  //     } else {
  //       MySwal.fire({
  //         icon: 'error',
  //         title: i18n.t("processFailure"),
  //         text: i18n.t("youDidntRate")
  //       });
  //     }
  //   } catch (error) {
  //     MySwal.fire({
  //       icon: 'error',
  //       title: i18n.t("processFailure"),
  //       text: i18n.t("youDidntRate")
  //     });
  //   }
  // }
};

  useEffect(() => {
    document.title = i18n.t("bookNewTable");
  }, []);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 21,
      width: "auto",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  return (
    <>
      <Navbar />
      <div className="headerNav"></div>
      <div className="ReservationWrapper">
        <div className="form">
          <h3>{t("bookNewTable")}</h3>
          <Grid container spacing={2} width={{ md: 1000, sm: 500, xs: 300 }} style={{paddingBottom:'4vw'}}>
            <Grid md={6} sm={12} xs={12}>
            <FormControl className="formcontrol" variant="standard">
              <TextField
                id="name-input"
                label={t("theName")}
                variant="outlined"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              {nameError && (
                <div className="error-message" style={{color:'red', fontWeight:'900'}}>{nameError}</div>
              )}
            </FormControl>
              <br />
              <FormControl>
                <div className="telcontainer">
                  <span>{t("phoneNumber")}</span>
                  <PhoneInput
                    country={"sa"}
                    onlyCountries={["us", "eg", "sa", "ae"]}
                    inputClass={"intlTel"}
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
                {phoneError && (
                <div className="error-message" style={{color:'red', fontWeight:'900'}}>{phoneError}</div>
              )}
              </FormControl>
            </Grid>
            <Grid md={6} sm={12} xs={12}>
            <FormControl className="formcontrol" variant="standard" style={{ marginBottom: "2.4vw" }}>
              <TextField
                id="email-input"
                label={t("email")}
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              {emailError && (
                <div className="error-message" style={{color:'red', fontWeight:'900'}}>{emailError}</div>
              )}
            </FormControl>
              <br />
              <FormControl className="formcontrol" variant="standard">
                <InputLabel
                  sx={{ fontFamily: "inherit" }}
                  shrink
                  htmlFor="date-input"
                >
                  {t("date")}
                </InputLabel>
                <BootstrapInput
                  id="date-input"
                  type="date"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
             {dateError && (
                <div className="error-message" style={{color:'red', fontWeight:'900'}}>{dateError}</div>
              )}
              </FormControl> <br />
              <FormControl className="formcontrol" variant="standard" >
                <InputLabel
                  sx={{ fontFamily: "inherit" }}
                  shrink
                  htmlFor="time-input"
                >
                  {t("time")}
                </InputLabel>
                <BootstrapInput
                  id="time-input"
                  type="time"
                  value={time}
                  onChange={(event) => setTime(event.target.value)}
                />
                {timeError && (
                  <div className="error-message" style={{color:'red', fontWeight:'900'}}>{timeError}</div>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row-reverse",
              marginTop: "20px",
            }}
          >
            <p style={{fontWeight:'900', color:'#191970'}}>{t("peopleNum")}</p>
            <div className="counter">
              <div>
                <Button
                  onClick={decrement}
                  sx={{ fontSize: 16 }}
                  variant="contained"
                >
                  -
                </Button>
              </div>
              <div className="countertxt">{personCount}</div>
              <div>
                <Button
                  onClick={increment}
                  sx={{ fontSize: 16 }}
                  variant="contained"
                >
                  +
                </Button>
              </div>
            </div>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "20px",
            }}
          >
            <Button
              ref={element}
              onClick={handleSubmit}
              sx={{
                fontSize: 16,
                width: { md: "30%", sm: "50%", xs: "60%" },
                borderRadius: 2,
              }}
              variant="contained"
            >
              {t("TableResrve")}
            </Button>
            {formError && (
              <div className="error-message" style={{color:'red', fontWeight:'900'}}>{formError}</div>
            )}
          </Grid>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reservation;
