import * as React from "react"
const Mushroom = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4klEQVR4nNXUMUoDQRiG4aeIBO3scoKI3kFbU6Q1oFUuoY2IuYCi1gk5hHgMsdHCA6ilFmk1MvALYZld2LHKB1/z/rMvw+7Mss7Zwx1esIg+4xa7bUTdEH1jWdM0u4m1jdnAQ4Oo2vt4pjYXLWR/Pa+TbeKzQPiFrZzwoEC2jO7nhMN/CIc54U6h7Af9uvd4gjkmGOEDV9iOXgcbxZo5jpu+cm7HhytsECzNWmcSu+mssE6wyxLhU9yGahJ7LBEuMM7wccxa5x2nGX6GtxLhDK84qjSxaYmwF7+u6rlLLM3WNL+mpH4ig5ORxwAAAABJRU5ErkJggg=="
      width={20}
      height={20}
    />
  </svg>
)
export default Mushroom
