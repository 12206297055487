import React, { useState } from "react";
import Logo from "../../assets/icon.jpg";
import "./index.scss";
import Typewriter from "typewriter-effect";

const ImgLoader = () => {
  return (
    <div className="scaling-image-container">
      <img src={Logo} alt="Scaling Image" className={"scaling"} />
      {/* <div className="loading-text">Loading...</div> */}
     <div className="typeWrite">
     <Typewriter
        options={{
          strings: ["Loading ..."],
          autoStart: true,
          loop: true,
        }}
      />
     </div>
    </div>
  );
};

export default ImgLoader;
