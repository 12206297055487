import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import Footer from "../../../components/Footer/Footer";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@mui/material/FormControl";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  LandingPage_Data,
  Main_Api_Link,
  all_Reviews,
  overallRate_api,
} from "../../../constants/ApiConstants";
import axios from "axios";
import Loader from "../../../components/Loader";
import { Star } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ImgLoader from "../../../components/ImgLoader/ImgLoader";
const RatingSwal = withReactContent(Swal);

const ResturantReviews = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const navigate = useNavigate();
  let { userId } = useParams();
  const [data, setData] = useState();
  const [overallRate, setOverallRate] = useState();
  const [allReviews, setAllReviews] = useState();
  const [showReviews, setShowReviews] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
    getOverAllRates();
    getReviews();
  }, []);

  const getData = () => {
    const url = process.env.REACT_APP_API_URL + LandingPage_Data + userId;
    // const url = Main_Api_Link + LandingPage_Data + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    console.log(url);
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setData(res.data);
        document.title = `Rating Of ${res.data.name}`;
        changeLanguage(res.data.currentLanguage);
        setLoading(false);
      })
      .then(() => {
        setLoading(false);
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const getOverAllRates = () => {
    setLoading(true);
    const url = process.env.REACT_APP_API_URL + overallRate_api + userId;
    // const url = Main_Api_Link + overallRate_api + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    console.log(url);
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setOverallRate(res.data);
        setLoading(false);
        // console.log("all rate is ", res.data);
      })
      .then(() => {
        setLoading(false);
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const getReviews = () => {
    setLoading(true);
    const url = process.env.REACT_APP_API_URL + all_Reviews + userId;
    // const url = Main_Api_Link + all_Reviews + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    console.log(url);
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setAllReviews(res.data.reviews);
        setLoading(false);
        // console.log("all reviews is ", res.data.reviews);
      })
      .then(() => {
        setLoading(false);
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <ImgLoader />
        </>
      ) : (
        <>
          <div className="headerNav"></div>
          <div className="RatingWrapper">
            <div className="form formWrapper">
              <h2
                style={{
                  marginBottom: "3vw",
                  marginTop: "3vw",
                  color: data.textColor,
                }}
              >
                {data.name} : {t("resturantName")}
              </h2>
              <form>
                <Grid
                  container
                  justifyContent="center" // Add this line
                  alignItems="center" // Add this line
                  sx={{
                    width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                    paddingBottom: "4vw",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <div style={{ marginBottom: "4rem" }}>
                      <div className="titleanddesc">
                        <h5 style={{ color: data.textColor }} className="desc">
                          {data.desc}
                        </h5>
                        <h5 style={{ color: data.textColor }} className="desc">
                          {data.phone}
                        </h5>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div>
                      <h5>{t("ResturantsRatingAvg")}</h5>
                      <div className="rate-table">
                        <div className="rate-column">
                          <p>{t("servicesRating")}</p>
                          <div className="stars-wrapper">
                            {overallRate &&
                              Array.from(
                                { length: overallRate.service_rating },
                                (_, i) => (
                                  <Star key={i} style={{ fill: "#Ffd300" }} />
                                )
                              )}
                          </div>
                        </div>
                        <div className="rate-column">
                          <p>{t("foodRating")}</p>
                          <div className="stars-wrapper">
                            {overallRate &&
                              Array.from(
                                { length: overallRate.food_rating },
                                (_, i) => (
                                  <Star key={i} style={{ fill: "#Ffd300" }} />
                                )
                              )}
                          </div>
                        </div>
                        <div className="rate-column">
                          <p>{t("overallRating")}</p>
                          <div className="stars-wrapper">
                            {overallRate &&
                              Array.from(
                                { length: overallRate.overall_rating },
                                (_, i) => (
                                  <Star key={i} style={{ fill: "#Ffd300" }} />
                                )
                              )}
                          </div>
                        </div>
                        <div className="rate-column">
                          <p>{t("overallRatingAvg")}</p>
                          <div className="stars-wrapper">
                            {overallRate &&
                              Array.from(
                                { length: overallRate.overall_average },
                                (_, i) => (
                                  <Star key={i} style={{ fill: "#Ffd300" }} />
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      onClick={() => setShowReviews(!showReviews)}
                      variant="outlined"
                      color="info"
                      size="small"
                      sx={{ marginX: 2, marginY: 2 }}
                    >
                      {t("showRating")}
                    </Button>
                    {showReviews && (
                      <div className="reviews-container">
                        {allReviews ? (
                          <>
                            {allReviews.map((review, index) => (
                              <div
                                className="single-rate-container"
                                key={index}
                              >
                                <div
                                  className="single-rate"
                                  style={{ borderColor: data.mainColor }}
                                >
                                  <p>
                                  {review.customer_name} :  {t("customerNameIs")}
                                  </p>
                                  <p>
                                  {review.customer_phone} : {t("customerPhoneIs")}
                                  </p>
                                  <p>{review.body}</p>
                                  <div className="sec-rate-container">
                                    <div className="rate-column">
                                      <p>{t("foodRating")}</p>
                                      <div className="stars-wrapper">
                                        {Array.from(
                                          { length: review.food_rating },
                                          (_, i) => (
                                            <Star
                                              key={i}
                                              style={{ fill: "#Ffd300" }}
                                            />
                                          )
                                        )}
                                      </div>
                                    </div>
                                    <div className="rate-column">
                                      <p>{t("servicesRating")}</p>
                                      <div className="stars-wrapper">
                                        {Array.from(
                                          { length: review.service_rating },
                                          (_, i) => (
                                            <Star
                                              key={i}
                                              style={{ fill: "#Ffd300" }}
                                            />
                                          )
                                        )}
                                      </div>
                                    </div>
                                    <div className="rate-column">
                                      <p>{t("overallRating")} </p>
                                      <div className="stars-wrapper">
                                        {Array.from(
                                          { length: review.rating },
                                          (_, i) => (
                                            <Star
                                              key={i}
                                              style={{ fill: "#Ffd300" }}
                                            />
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <p>{t("noRatings")}</p>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Button
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="info"
                  size="small"
                  sx={{ marginX: 2 }}
                >
                  {t("back")}
                </Button>
              </form>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ResturantReviews;
