import * as React from "react"
const Semsem = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABT0lEQVR4nN3SvUtWYRzG8U801mou4RwEos8e2N6ii5NTNOiii64GiT2CL4tCWfRnlIOKi2/g6KApCoVGUatJLz5x4AoO4svhmaILbs7hOr/f91y/+775n3QbT/AOH3CKY0w0A+vFERpZRwE18BP1qqAbeJ7GAeyiH58wee4HlVQvNc1jJf5OvMkk/V4F9jhNg1jEA2zhTfydJD3G4XWw+/iGE6yl8RbeBlZAZfxG/Et1E5uYxQxqmI53luYi6TK2AyymuVQjKdrLeojX8TaStBj/ZQ7qY7wL1ZYNLpq78Qhf8AuvMJU9W0jNGXquSlcrnWq9dGU+owvtWMVQ/LGrYHfyHE3xb/zAeJK+z16e5Psz1+gAHXl/WoJOBLiPOXxFnwqaT/F5aHmt456K6k9TGfp3/OKghnOlKmuxlKQM7cRdTagVS0n6InewpRnQv60/igpxk5Q6mwgAAAAASUVORK5CYII="
      width={20}
      height={20}
    />
  </svg>
)
export default Semsem
