import * as React from "react"
const Cheese = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M3.8 4.6C.6 6.3.1 10.2 2.6 13.8 3.9 15.7 5 16 11 16c7.5 0 8.9-.9 7-4.6-1.1-1.9-1.1-2.6 0-3.9 1.8-2.2-.1-3.5-5.2-3.5-2 0-4.2-.2-5-.5-.7-.2-2.5.2-4 1.1zm6.5 1.1c-1.3.2-3.3.2-4.5 0-1.3-.2-.3-.4 2.2-.4s3.5.2 2.3.4zM14.5 8c.3.5.1 1-.4 1-.6 0-1.1-.5-1.1-1 0-.6.2-1 .4-1 .3 0 .8.4 1.1 1zM7 10c0 .5-.7 1-1.5 1S4 10.5 4 10c0-.6.7-1 1.5-1S7 9.4 7 10zm4.5 4c-.5.8-1.1.8-1.7.2-.6-.6-.7-1.6-.3-2.2.5-.8 1.1-.8 1.7-.2.6.6.7 1.6.3 2.2z" />
  </svg>
)
export default Cheese
