import * as React from "react"
const Hazenut = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEklEQVR4nNXUu0oDQRjF8Z+tpBJvtQSLvEAKQbFI0gha+QLq6+QBRESsJZWC1hbaiFaKsdRCBLGSeCmUha8YZNxcCsEDpzlz9r/f7s4s/1lL2MUdXsPdyBaHAU3jCF+4QBsb4XZkxdphdEs1iRtcYh1jqKAVrkRWrF3hGhNlwP14tHqSdWOiwrdJXo/u3m+wYvxPnP3Im9gJN5K8mPQcH5jKAZvJJJv6ayvpN3KFVlLo4QDVTK8aa72k38wBa0kh9T2Oww+/dGo54PiPuw7qXlybVWcEYKfsRa+MAFzVRydDwE5j+5RqDs8DwF4wb0Atxyko+xDZvVemBTxmYE/D/m0kmsE23vAex292VFiqtfDf6xvLmYjhYyndgQAAAABJRU5ErkJggg=="
      width={20}
      height={20}
    />
  </svg>
)
export default Hazenut
