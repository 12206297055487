import image1 from "./demoImg/1.jpg";
import image2 from "./demoImg/2.jpg";
import image3 from "./demoImg/3.jpg";
import image4 from "./demoImg/4.jpg";
import image5 from "./demoImg/5.jpg";
import image6 from "./demoImg/6.jpg";
import image7 from "./demoImg/7.jpg";
import image8 from "./demoImg/8.jpg";
import image9 from "./demoImg/9.jpg";
import image10 from "./demoImg/10.jpg";
import image11 from "./demoImg/11.jpg";
import image12 from "./demoImg/12.jpg";
import image13 from "./demoImg/13.jpg";
import image14 from "./demoImg/14.jpg";
import image15 from "./demoImg/15.jpg";
import image16 from "./demoImg/16.jpg";
import image17 from "./demoImg/17.jpg";
import image18 from "./demoImg/18.jpg";
import image19 from "./demoImg/19.jpg";
import image20 from "./demoImg/20.jpg";

const coverImages = {
  image1,
  image2,
  image3,
};

const images = {
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
};

export { coverImages, images };
