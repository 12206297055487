export const Main_Api_Link = "https://beta.talabhub.com/api";

export const LandingPage_Data = "/landing-page/";

export const MenuPage_Data = "/menu/";

export const MenuPage_Data_Carrosel = "/menu/categories/";

export const MenuPage_Data_Food = "/menu/food/";

export const tables = "/tables/";

export const get_parties = "/getPartyItems/";

export const store_parties = "/storeParty";

export const overallRate_api = "/reviews/average/"

export const all_Reviews = "/reviews/"