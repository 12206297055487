import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import "./index.scss";
import Navbar from "../../components/Navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// the carrosel
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Footer from "../../components/Footer/Footer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, IconButton, Modal } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { addToCart, clearCart, removeFromCart } from "../../features/cartSlice";
import CloseIcon from "@mui/icons-material/Close";
// for carrosel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Loader from "../../components/Loader";
import Loader1 from "../../components/Loader1";
import {
  Main_Api_Link,
  MenuPage_Data,
  MenuPage_Data_Carrosel,
  MenuPage_Data_Food,
} from "../../constants/ApiConstants";
import {
  Cheese,
  Choco,
  Egg,
  Fish,
  Garlic,
  Glutin,
  Hazenut,
  Milk,
  Mushroom,
  Onion,
  Peanuts,
  Semsem,
  Soya,
} from "../../components/Svg/Icons";
import {
  activeBranch,
  openDrawer,
  storeBranchId,
  storeData,
  storeUserId,
} from "../../features/dataSlice";
import { Facebook, Instagram, Star, Twitter } from "@mui/icons-material";
import Linkedin from "../../components/Svg/Lindedin/Linkedin";
import Youtube from "../../components/Svg/Youtube/Youtube";
import Snapchat from "../../components/Svg/Snapchat/Snapchat";
import Sugar from "../../components/Svg/Icons/Sugar";
//! for demo only
import { coverImages, images } from "../../assets";
import coverImage from "../../assets/demoImg/1.jpg";
import { useTranslation } from "react-i18next";
import ImgLoader from "../../components/ImgLoader/ImgLoader";
//? for demo only
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//! for demo purposes only

const getRandomCoverImage = () => {
  const keys = Object.keys(coverImages);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return images[randomKey];
};

const getRandomMenuImage = () => {
  const keys = Object.keys(images);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return images[randomKey];
};

//? for demo purposes only

const MenuPage = (props) => {
  const { t, i18n } = useTranslation();
  //! the lang change here !
  // const changeLanguage = (lang) => {
  //   i18n.changeLanguage(lang);
  // };
  // useEffect(()=>{
  //   changeLanguage("en")
  // },[])
  const RandomCoverImg = { filename: getRandomCoverImage() };
  const dispatch = useDispatch();
  const landingData = useSelector((state) => state.dataReducer.landingData);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [data, setData] = useState([]);
  const [activeState, SetActiveState] = useState(1);
  const [currentMenu, setCurrentMenu] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataSize, setSelectedDataSize] = useState();
  const [inialCarouselState, setInialCarouselState] = useState(true);
  const [count, setCount] = useState(1);
  const [menuUrl, setMenuUrl] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const handleOpenInfoModal = () => setOpenInfoModal(true);
  const handleCloseInfoModal = () => setOpenInfoModal(false);
  const [activeBtn, setActiveBtn] = useState(1);
  const HandleChangeBtns = (value) => {
    setActiveBtn(value);
  };
  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  let { userId } = useParams();
  let { branchId } = useParams();

  function isUrlValid(url) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  }

  useEffect(() => {
    getMainData();
    getCarroselData();
    setInialCarouselState(true);
    storeMyIds();
  }, []);

  const storeMyIds = () => {
    dispatch(storeUserId(userId));
    if (branchId) {
      dispatch(storeBranchId(branchId));
      dispatch(activeBranch());
    }
  };

  const getMainData = () => {
    let url;
    if (branchId) {
      url = process.env.REACT_APP_API_URL + "/branches/menu/" + branchId;
      // url = Main_Api_Link + "/branches/menu/" + branchId;
    } else {
      url = process.env.REACT_APP_API_URL + MenuPage_Data + userId;
      // url = Main_Api_Link + MenuPage_Data + userId;
    }
    // console.log("url is ", url);

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setData(res.data);
        console.log("====================================");
        console.log(res?.data);
        console.log("====================================");
        if (branchId) {
          if (res.data.title == undefined) {
            document.title = `Menu branch`;
          } else {
            // console.log("branch", res.data);
            document.title = `Menu branch ${res.data.title}`;
          }
        } else {
          document.title = `Menu ${res.data.title}`;
        }
        dispatch(storeData(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCarroselData = () => {
    let url;
    if (branchId) {
      url =
        process.env.REACT_APP_API_URL +
        "/branches" +
        MenuPage_Data_Carrosel +
        branchId;
      // url = Main_Api_Link + "/branches" + MenuPage_Data_Carrosel + branchId;
    } else {
      url = process.env.REACT_APP_API_URL + MenuPage_Data_Carrosel + userId;
      // url = Main_Api_Link + MenuPage_Data_Carrosel + userId;
    }
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setCarroselData(res.data);
        console.log("==2222==================================");
        console.log(res.data);
        console.log("====================================");
        if (inialCarouselState === true) {
          if (res.data[0]) {
            menuChecker(res.data[0].id);
            setCategoryName(res.data[0].title);
          } else {
            menuChecker();
            setCategoryName();
          }
        } else {
        }
      })
      .then(() => {
        setLoading(false);
        setInialCarouselState(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFoodData = (id) => {
    let url;
    if (branchId) {
      url =
        process.env.REACT_APP_API_URL +
        "/branches" +
        MenuPage_Data_Food +
        branchId +
        "/" +
        id;
      // Main_Api_Link + "/branches" + MenuPage_Data_Food + branchId + "/" + id;
      // console.log("branch url is ", url);
    } else {
      url = process.env.REACT_APP_API_URL + MenuPage_Data_Food + id;
      // url = Main_Api_Link + MenuPage_Data_Food + id;
      // console.log("not branch");
    }

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setCurrentMenu(res.data);
        console.log("2222222222223333333====================================");
        console.log(res.data);
        console.log("====================================");
      })
      .then(() => {
        setLoading1(false);
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImagePress = (item) => {
    SetActiveState(item.id);
    menuChecker(item.id);
    // console.log(item);
    setCategoryName(item.title);
  };

  const menuChecker = (id) => {
    setLoading1(true);
    getFoodData(id);
  };

  useEffect(() => {
    SetActiveState(1);
  }, []);

  // tempData
  const [carroselData, setCarroselData] = useState([]);

  const handleClose = () => {
    setOpenModal(false);
    setSelectedDataSize();
    setCount(1);
    setSelectedData();
    // removeAllItems();
  };

  const handleOpenModal = (data) => {
    setOpenModal(true);
    setSelectedData(data);
  };

  const handleAddToCart = (item) => {
    setOpenModal(false);
    dispatch(addToCart(item));
  };

  const handleAddSelectSize = (item) => {
    setSelectedDataSize(item);
    const oldPrice = selectedData.price || 0;
    const obj = item.options;
    const values = Object.values(obj);
    const optionKeys = Object.keys(obj);
    const firstOptionKey = optionKeys.length > 0 ? optionKeys[0] : null;

    if (selectedData.options === firstOptionKey) {
      console.log("exist !!");
      console.log(selectedData);
      setSelectedData((prevData) => ({
        ...prevData,
        price: prevData.oldPrice2,
        options: "",
        count: count,
        activeOption: false,
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        price: item.price,
        options: optionKeys[0], // Set the specific option key for the selected variant
        count: count,
        activeOption: true,
        oldPrice2: oldPrice,
      }));
      console.log(selectedData);
      console.log(optionKeys[0]);
      console.log("option has been updated");
    }
  };

  const handleAddExtraSelectSize = (item) => {
    const extraName = item.name;
    const oldPrice = selectedData.price;

    if (selectedData.extraName) {
      if (selectedData.extraName.includes(extraName)) {
        // Extra already exists, remove it and reset price
        const updatedExtraName = selectedData.extraName.filter(
          (name) => name !== extraName
        );
        const updatedPrice = oldPrice - item.price;

        setSelectedData((prevData) => ({
          ...prevData,
          price: updatedPrice,
          activeExtra: updatedExtraName.length > 0, // Set activeExtra to false if extraName array is empty
          extraName: updatedExtraName,
        }));

        console.log("Extra has been removed");
        console.log(selectedData);
      } else {
        // Add new extra
        setSelectedData((prevData) => ({
          ...prevData,
          price: item.price + oldPrice,
          activeExtra: true,
          extraName: [...prevData.extraName, extraName],
        }));

        console.log("Extra has been added");
        console.log(selectedData);
      }
    } else {
      // No extras available, add new extra
      setSelectedData((prevData) => ({
        ...prevData,
        price: item.price + oldPrice,
        activeExtra: true,
        extraName: [extraName],
      }));

      console.log("No extra data available, extra has been added");
    }
  };

  const ModifySelectedDataNumber = () => {
    setSelectedData({
      ...selectedData,
      count: count,
    });
    // console.log(count);
  };

  const handleLastSelectedData = async () => {
    if (!selectedData.options) {
      ModifySelectedDataNumber();
    }
    if (selectedData.count) {
      dispatch(openDrawer());
      handleAddToCart(addToCart(selectedData));
    }
  };

  // useEffect(() => {
  //   console.log("count has been updated", selectedData);
  // }, [selectedData]);

  useEffect(() => {
    console.log(selectedData?.price);
  }, [selectedData]);

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <>
      {loading ? (
        <>
          <ImgLoader />
        </>
      ) : (
        <div style={{ backgroundColor: data.backgroundColor }}>
          <Navbar id={data.id} logo={data?.logo} data={data} />
          {/* start modal */}
          <Dialog
            open={openModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
          >
            <DialogTitle style={{ backgroundColor: data.backgroundColor }}>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}></Box>
                <Box>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent style={{ backgroundColor: data.backgroundColor }}>
              <Grid container>
                <Grid
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: 5,
                  }}
                >
                  <img
                    src={data?.logo}
                    //! for demo purposes only
                    // src={getRandomMenuImage()}
                    //  src={"https://www.gifpng.com/250x250/333333/ffffff"}
                    alt="11"
                    width={250}
                    height={250}
                  />
                </Grid>
                <Grid md={6} sm={12} xs={12}>
                  <Box textAlign={"center"}>
                    <h4>
                      {!selectedData?.name ? "No Title" : selectedData?.name}
                    </h4>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LocalAtmIcon
                        sx={{ color: data.textColor, marginX: 1 }}
                      />
                      <p>
                        {t("ryal")} {selectedData?.price * count}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <WhatshotIcon
                        sx={{ color: data.textColor, marginX: 1 }}
                      />

                      <p>{selectedData?.kcal} kcal</p>
                    </Box>
                    <p>
                      {!selectedData?.description
                        ? "No Description"
                        : selectedData?.description}
                    </p>
                    {selectedData?.warnings && (
                      <>
                        <p>{t("contain")}</p>
                        <span className="mr-2 warnings-wrapper">
                          <div className="contain-wrapper">
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("milk") && (
                                <Tooltip title="Milk">
                                  <div>
                                    <Milk fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("sugar") && (
                                <Tooltip title="sugar">
                                  <div>
                                    <Sugar fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("egg") && (
                                <Tooltip title="egg">
                                  <div>
                                    <Egg fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("cheese") && (
                                <Tooltip title="cheese">
                                  <div>
                                    <Cheese fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("gluten") && (
                                <Tooltip title="gluten">
                                  <div>
                                    <Glutin fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("hazelnut") && (
                                <Tooltip title="hazelnut">
                                  <div>
                                    <Hazenut fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes(
                                "chocolate-bar--v1"
                              ) && (
                                <Tooltip title="Chocolate">
                                  <div>
                                    <Choco fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("mushroom") && (
                                <Tooltip title="mushroom">
                                  <div>
                                    <Mushroom fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("onion") && (
                                <Tooltip title="onion">
                                  <div>
                                    <Onion fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("soy") && (
                                <Tooltip title="soy">
                                  <div>
                                    <Soya fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                            {selectedData?.warnings &&
                              selectedData.warnings.includes("sesame--v1") && (
                                <Tooltip title="Semsem">
                                  <div>
                                    <Semsem fill={data.textColor} />
                                  </div>
                                </Tooltip>
                              )}
                          </div>
                        </span>
                      </>
                    )}

                    {/* for extras */}
                    {selectedData?.contain?.length !== 0 && <span>Extra</span>}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: 5,
                        marginTop: 5,
                        width: 280,
                        flexWrap: "wrap",
                      }}
                    >
                      {selectedData?.contain &&
                        selectedData?.contain.map((size) => {
                          return (
                            <Button
                              key={size.id}
                              style={{
                                backgroundColor:
                                  selectedData.extraName &&
                                  selectedData.extraName.includes(size.name)
                                    ? data.textColor
                                    : null,
                                color:
                                  selectedData.extraName &&
                                  selectedData.extraName.includes(size.name)
                                    ? data.backgroundColor
                                    : data.textColor,
                                borderColor: data.textColor,
                                width: 100,
                                marginRight: 5,
                                marginTop: 5,
                              }}
                              variant="outlined"
                              onClick={(e) => handleAddExtraSelectSize(size)}
                            >
                              {size.name}
                            </Button>
                          );
                        })}
                    </Box>

                    {selectedData?.variant?.length !== 0 && (
                      <span>{t("Variants")}</span>
                    )}

                    {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: 5,
                        marginTop: 5,
                        width: 280,
                        flexWrap: "wrap",
                      }}
                    >
                      {selectedData?.variants &&
                        selectedData?.variants.map((size) => {
                          const optionKey = Object.keys(size.options)[0]; // get the first key in the options object
                          const optionKey2 = Object.keys(size.options)[1]; // get the second key in the options object
                          const optionValue = size.options[optionKey]?.en || ""; // get the English value from the options, fallback to 'N/A' if it doesn't exist
                          const optionValue2 =
                            size.options[optionKey2]?.en || ""; // get the English value from the options, fallback to 'N/A' if it doesn't exist

                          return (
                            <Grid
                              md={6}
                              sm={12}
                              xs={12}
                              key={size.id}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: 1,
                              }}
                            >
                          <Button
                                  key={size.id}
                                  style={{
                                backgroundColor:
                                  selectedData.activeOption &&
                                  selectedData.options.includes(size.name)
                                    ? data.textColor
                                    : null,
                                color:
                                  selectedData.extraName &&
                                  selectedData.extraName.includes(size.name)
                                    ? data.backgroundColor
                                    : data.textColor,
                                borderColor: data.textColor,
                                width: 100,
                                marginRight: 5,
                              }}
                                  variant="contained"
                                  onClick={(e) => handleAddSelectSize(size)}
                                >
                                  {optionValue}{" "}
                                  {optionValue2 ? -optionValue2 : ""}
                                </Button>
                            </Grid>
                          );
                        })}
                    </Box> */}

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: 5,
                        marginTop: 5,
                        width: 280,
                        flexWrap: "wrap",
                      }}
                    >
                      {selectedData?.variants &&
                        selectedData?.variants.map((size) => {
                          const optionKey = Object.keys(size.options)[0];
                          const optionValue = size.options[optionKey]?.en || "";

                          return (
                            <Grid
                              md={6}
                              sm={12}
                              xs={12}
                              key={size.id}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: 1,
                              }}
                            >
                              {selectedData?.options === optionKey ? (
                                <Button
                                  key={size.id}
                                  style={{
                                    backgroundColor:
                                      size.id === selectedDataSize.id
                                        ? data.textColor
                                        : "white",
                                    borderColor: data.textColor,
                                  }}
                                  variant="contained"
                                  onClick={(e) => handleAddSelectSize(size)}
                                >
                                  {optionValue}
                                </Button>
                              ) : (
                                <Button
                                  key={size.id}
                                  style={{
                                    borderColor: data.textColor,
                                    color: data.textColor,
                                  }}
                                  variant="outlined"
                                  onClick={(e) => handleAddSelectSize(size)}
                                >
                                  {optionValue}
                                </Button>
                              )}
                            </Grid>
                          );
                        })}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "center",
                      }}
                    >
                      <div className="counter">
                        <div>
                          <Button
                            style={{
                              maxWidth: "30px",
                              maxHeight: "30px",
                              minWidth: "30px",
                              minHeight: "30px",
                              backgroundColor: data.textColor,
                            }}
                            onClick={decrementCount}
                            sx={{ fontSize: 16 }}
                            variant="contained"
                            size="small"
                          >
                            -
                          </Button>
                        </div>
                        <div className="countertxt">{count}</div>
                        <div>
                          <Button
                            style={{
                              maxWidth: "30px",
                              maxHeight: "30px",
                              minWidth: "30px",
                              minHeight: "30px",
                              backgroundColor: data.textColor,
                            }}
                            onClick={incrementCount}
                            sx={{ fontSize: 16 }}
                            variant="contained"
                            size="small"
                          >
                            +
                          </Button>
                        </div>
                      </div>
                      <div>
                        <Link
                        // to={`/cart-checkout/${data.id}`}
                        // state={{ data: data }}
                        >
                          <Button
                            style={{
                              maxWidth: "150px",
                              minWidth: "150px",
                              backgroundColor: data.textColor,
                            }}
                            variant="contained"
                            color="primary"
                            onClick={handleLastSelectedData}
                            // onClick={()=>dispatch(removeFromCart(selectedData))}
                            // onClick={()=>dispatch(clearCart())}
                            // onClick={() => console.log("success")}
                            sx={{ fontSize: 16 }}
                          >
                            {t("addToCart")}
                          </Button>
                        </Link>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          {/* end modal */}
          {/* Info Modal */}
          <Modal
            open={openInfoModal}
            onClose={handleCloseInfoModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style1}>
              {data.show_background_image === 1 ? (
                <img
                  className="logosmall"
                  src={data?.logo}
                  // src={getRandomMenuImage()}
                  // src={"https://www.gifpng.com/100x100/333333/ffffff"}
                  alt="Logosm"
                />
              ) : (
                ""
              )}
              <h3>{data.title}</h3>
              <p>{data.description}</p>
              <hr />
              <div className="btnsWrapper">
                <p
                  onClick={() => HandleChangeBtns(2)}
                  style={{
                    backgroundColor:
                      activeBtn === 2
                        ? data.mainColor
                          ? data.mainColor
                          : "#84cec3"
                        : "",
                    color:
                      activeBtn === 2
                        ? "#fff"
                        : data.mainColor
                        ? data.mainColor
                        : "#84cec3",
                  }}
                  className="btnsWrapperItem"
                >
                  {t("aboutUs")}
                </p>
                <p
                  onClick={() => HandleChangeBtns(1)}
                  style={{
                    backgroundColor:
                      activeBtn === 1
                        ? data.mainColor
                          ? data.mainColor
                          : "#84cec3"
                        : "",
                    color:
                      activeBtn === 1
                        ? "#fff"
                        : data.mainColor
                        ? data.mainColor
                        : "#84cec3",
                  }}
                  className="btnsWrapperItem"
                >
                  {t("rates")}
                </p>
              </div>
              <div style={{ textAlign: "center" }}>
                {activeBtn === 1 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // backgroundColor:"red"
                    }}
                  >
                    {Object.keys(landingData).length !== 0 && (
                      <>
                        <p>{t("rateAvg")} </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {Array(landingData.restaurantAvgRating)
                            .fill(0)
                            .map((_, i) => (
                              <Star key={i} />
                            ))}
                        </div>
                      </>
                    )}

                    <Link
                      to={`/restaurantReviews/${data.id}`}
                      className="btnClick"
                    >
                      {t("showAllRate")}
                    </Link>
                  </div>
                ) : (
                  <>
                    {data?.can_show_phone === 1 && (
                      <>
                        <h5>{t("phoneNumber")}</h5>
                        <p>{data?.phone}</p>

                        <hr />
                      </>
                    )}
                    {data?.showLocation === 1 && (
                      <>
                        <h5>{t("address")}</h5>
                        <p>{data?.address}</p>
                        <hr />
                      </>
                    )}
                    <h5>{t("socialSites")}</h5>
                    <div className="socialBtns2">
                      {data.twitterAccountLink !== null && (
                        <div
                          onClick={() =>
                            window.open(data.twitterAccountLink, "_blank")
                          }
                          className="icon3"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                        >
                          <Twitter />
                        </div>
                      )}
                      {data.InstagramAccountLink !== null && (
                        <div
                          onClick={() =>
                            window.open(data.InstagramAccountLink, "_blank")
                          }
                          className="icon3"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                        >
                          <Instagram />
                        </div>
                      )}
                      {data.facebookAccountLink !== null && (
                        <div
                          onClick={() =>
                            window.open(data.facebookAccountLink, "_blank")
                          }
                          className="icon3"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                        >
                          <Facebook />
                        </div>
                      )}
                      {data.linkedinAccountLink !== null && (
                        <div
                          onClick={() =>
                            window.open(data.linkedinAccountLink, "_blank")
                          }
                          className="icon3"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                        >
                          <Linkedin />
                        </div>
                      )}
                      {data.youtubeAccountLink !== null && (
                        <div
                          onClick={() =>
                            window.open(data.youtubeAccountLink, "_blank")
                          }
                          className="icon3"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                        >
                          <Youtube />
                        </div>
                      )}
                      {data.snapchatAccountLink !== null && (
                        <div
                          onClick={() =>
                            window.open(data.snapchatAccountLink, "_blank")
                          }
                          className="icon3"
                          style={
                            data?.mainColor
                              ? { color: data.mainColor }
                              : { color: "#84cec3" }
                          }
                        >
                          <Snapchat />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Modal>
          {/* the Slider images */}

          {data.showSliderImages === 1 ? (
            <>
              {data.sliderImages.length > 1 ? (
                // carrosel image
                <>
                  <OwlCarousel
                    className=""
                    items={1}
                    autoPlay={true}
                    autoplayHoverPause={true}
                    loop
                    margin={10}
                  >
                    {data.sliderImages.map((image) => (
                      <div className="item" key={image.filename}>
                        <div className="ImgContainer">
                          <div
                            className="bigImage"
                            style={{
                              backgroundImage: `url(${image.filename})`,
                              // backgroundImage: `url(${coverImage})`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          >
                            <div className="overlay">
                              <div
                                onClick={handleOpenInfoModal}
                                className="titleanddesc"
                              >
                                {data.showTitle === 1 && (
                                  <h3 className="title">{data.title}</h3>
                                )}
                                {data.showDescription === 1 && (
                                  <h5 className="desc">{data.description}</h5>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </>
              ) : (
                // only one image
                <>
                  <div className="ImgContainer">
                    <div
                      className="bigImage"
                      style={{
                        backgroundImage: `url(${data.backgroundImg})`,
                        // backgroundImage: `url(${coverImage})`,
                      }}
                    >
                      <div className="overlay">
                        <div
                          onClick={handleOpenInfoModal}
                          className="titleanddesc"
                        >
                          {data.showTitle === 1 && (
                            <h3 className="title">{data.title}</h3>
                          )}
                          {data.showDescription === 1 && (
                            <h5 className="desc">{data.description}</h5>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div style={{ marginBottom: "10rem" }}>
                <div className="ImgContainer">
                  <div
                    className="bigImage"
                    style={{ backgroundImage: `url(${data.backgroundImg})` }}
                  >
                    <div className="overlay">
                      {/*//? here if the data is empty this will render in the img */}
                      <div className="titleanddesc">
                        <h3 className="title">{t("noTitle")}</h3>
                        <h5 className="desc">{t("noDescription")}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* end of the Slider image */}
          <Container maxWidth="lg">
            {!categoryName && (
              <>
                <h4 className="nodataTxt">{t("noDataPleaseUpdate")}</h4>
              </>
            )}
            <OwlCarousel className="container-with-dots" loop margin={10} nav>
              {carroselData.map((image) => (
                <div key={image.id} className="item">
                  <Card
                    onClick={() => handleImagePress(image)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 3,
                      cursor: "pointer",
                      marginRight: 2,
                      marginBottom: 1,
                    }}
                    className="owl-card"
                  >
                    {data.showCategoryImages === 1 && (
                      <>
                        {isUrlValid(image.src) === true ? (
                          <CardMedia
                            className="cardimg"
                            component="img"
                            alt={image.alt}
                            // image={getRandomMenuImage()}
                            image={image.src}
                          />
                        ) : (
                          <>
                            <img
                              className="cardimg"
                              image={image.src}
                              // src={getRandomMenuImage()}
                              // src={
                              //   "https://www.gifpng.com/400x100/333333/ffffff"
                              // }
                              alt="Logosm"
                            />
                          </>
                        )}
                      </>
                    )}
                    <CardContent>
                      <Typography
                        className="cardtxt"
                        textAlign={"center"}
                        gutterBottom
                        marginBottom={0}
                        variant="p"
                        component="div"
                        style={{ wordWrap: "break-word" }}
                      >
                        {image.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </OwlCarousel>
            <Typography
              className="cardtxt"
              textAlign={"center"}
              variant="h5"
              component="h5"
              fontFamily={"inherit"}
            >
              {categoryName}
            </Typography>
            {/* <div>MenuPage {activeState}</div> */}
            <>
              {loading1 === true ? (
                <>
                  <Box marginTop={10}>
                    <Loader1 />
                  </Box>
                </>
              ) : (
                <Grid container spacing={4}>
                  {currentMenu.map((card) => (
                    <Grid item xs={12} sm={6} md={4} key={card.id}>
                      <Card
                        sx={{
                          cursor: "pointer",
                          borderRadius: 3,
                          position: "relative", // Add position relative to the Card
                        }}
                        onClick={() => handleOpenModal(card)}
                      >
                        {isUrlValid(card.src) === true ? (
                          <>
                            <CardMedia
                              component="img"
                              height="240"
                              // image={getRandomMenuImage()}
                              image={card.src}
                              alt={card.name}
                              sx={{ borderRadius: "0.625rem" }}
                              // onError={() => console.log("image fail")}
                            />
                            {card.is_new === 1 && (
                              <div className="badge-overlay">
                                <span className="badge new top-right">
                                  <img
                                    src="https://img.icons8.com/ios-glyphs/22/000000/new.png"
                                    className="d-inline"
                                    alt="isnew"
                                  />
                                  جديد
                                </span>
                              </div>
                            )}
                            {card.is_feature === 1 && (
                              <div className="badge-overlay2">
                                <span className="badge2 new bottom-left">
                                  <img
                                    src="https://img.icons8.com/flat-round/50/000000/star--v1.png"
                                    className="d-inline"
                                    alt="isfeature"
                                  />
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <img
                              className="cardimg2"
                              image={card.src}
                              // src={getRandomMenuImage()}
                              width={250}
                              height={380}
                              // src={
                              //   "https://www.gifpng.com/480x250/333333/ffffff"
                              // }
                              alt="Logosm"
                            />
                          </>
                        )}

                        <CardContent sx={{ marginBottom: -3 }}>
                          <Typography
                            textAlign={"center"}
                            gutterBottom
                            variant="p"
                            component="div"
                            style={{ wordWrap: "break-word" }}
                          >
                            {card.name}
                          </Typography>
                          <Typography
                            textAlign={"center"}
                            variant="body2"
                            style={{ wordWrap: "break-word" }}
                            color="text.secondary"
                          >
                            {t("ryal")}: {card.price}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MenuPage;
