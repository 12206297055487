import * as React from "react"
const Egg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVR4nNXRMQ4BQRTG8f82VBSUrqAX95A4AgXXEIU4i0YpCgkRZBuJAxBKRLJRIZPMJJvNLt+oeMkr5uXNb+bNwL9HERgCJ+AIDIDCt1gZ2ADPRK6Bki8WAKMUzOXY9sjRfIO5bKhYAOxSgKod1623KljPuNEKuCdqNQXsC+O67CngNGPzGWgBs1htooCHDNBtbsdqewWMMsCHRS+xWqSAN483vCrg0gNcKGDXA+woYB4IBcz05BCj8gENbQ8+YU43I5l3Mh9lcm5r8s1+L14xE46IVxn77QAAAABJRU5ErkJggg=="
      width={20}
      height={20}
    />
  </svg>
)
export default Egg
