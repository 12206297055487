import * as React from "react"
const Sugar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA7klEQVR4nM3UMStGURzH8Y+skpLNQsqMFDPLs2AxGLDpsT6LxcQL8AYUi8VEvAODl2DjXSARqf+t2+nS/3ncwbd+wznn17f+594OOUbR0yKnuGxDNIwO7iOd2BuYY3wWOfqLsBej1tPqXQ7MCM7w3jDyB86jk+aqQVTmOiubT8iqzGWEG1G+QBf7eMBbg3A9I1yN8lhtr/rCk9jCSXRWMsLFKE81CCumo7OQEc423E8pXI7OTEY40TBOKVyLznhGOIRXbBePw1JtvYeX6KZ4wkHxfNU5xKM+uMMNNn/IbXRk2cXzLz/099lOP8L/wxd1LFUpPRj+GwAAAABJRU5ErkJggg=="
      width={20}
      height={20}
    />
  </svg>
)
export default Sugar
