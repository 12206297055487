import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  TableHead,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./index.scss";
import Loader1 from "../Loader1";
import { useTranslation } from "react-i18next";

const TableComponent = ({ data, setData, loading, setTotal }) => {
  const handleAddRow = () => {};

  const handleInputChange = (index, value) => {
    const newData = data.map((item, i) => {
      if (i === index) {
        const count = parseInt(value);
        const subtotal = count * item.price;
        return { ...item, count, subtotal };
      }
      return item;
    });
    setData(newData);
    // console.log(newData)
  };
  const calculateTotal = () => {
    let total = 0;
    data.forEach((row) => {
      total += row.subtotal || 0;
    });
    setTotal(total)
    return total;
  };
  const handleRemoveRow = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const { t , i18n } = useTranslation();


  return (
    <>
      {loading ? (
        <Loader1 />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t("type")}</TableCell>
                  <TableCell>{t("price")}</TableCell>
                  <TableCell>{t("count")}</TableCell>
                  <TableCell>{t("totalPrice")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        value={row.name}
                        onChange={(e) => handleInputChange(e, index, "col1")}
                      />
                    </TableCell>
                    <TableCell>
                      <p>{row.price}</p>
                    </TableCell>
                    {/* the count here */}
                    <TableCell>
                      <TextField
                        name="count"
                        type="number"
                        value={row.count}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        variant="outlined"
                        inputProps={{
                          maxLength: 50,
                          step: "1",
                        }}
                      />
                    </TableCell>
                    {/* total price */}
                    <TableCell>{row.subtotal}</TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveRow(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              sx={{ marginTop: "1rem" }}
              variant="outlined"
              onClick={handleAddRow}
            >
              {t("addRow")}
            </Button>
          </TableContainer>
          <div className="lastTotal">
            <p>{t("Thetotal")} : {calculateTotal()}</p>
          </div>
        </>
      )}
    </>
  );
};

export default TableComponent;
