import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import "./index.scss";
import PhoneInput from "react-phone-input-2";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TableComponent from "../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Main_Api_Link,
  get_parties,
  store_parties,
} from "../../constants/ApiConstants";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);
const Parties = () => {
  const { t , i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const { userId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [formError, setFormError] = useState("");
  const [personCount, setPersonCount] = useState(1);
  const [time, setTime] = useState("10:00");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const handleTime = (e) => {
    setTime(e);
  };

  const handleSubmit = async (event) => {
    if (myData.length === 0) {
      MySwal.fire({
        icon: "error",
        title: i18n.t("processFailure"),
        text:i18n.t("noDataForResturant"),
      });
    } else {
      let data = {
        restorant_id: myData[0].restorant_id,
        name,
        email,
        phone,
        date,
        party_time: time,
        No_Person: personCount,
        address,
        total_price: total,
        party_item: [],
        qty: [],
      };

      myData.forEach((item) => {
        data.party_item.push(item.id);
        data.qty.push(item.count);
      });

      event.preventDefault();
      let hasError = false;

      // Validate name
      if (!name.match(/^[a-zA-Z\u0600-\u06FF\s]+$/)) {
        setNameError(i18n.t("plsEnterValidName"));
        hasError = true;
      } else {
        setNameError("");
      }

      // Validate email
      if (!email.match(/^\S+@\S+\.\S+$/)) {
        setEmailError(i18n.t("plsEnterValidEmail"));
        hasError = true;
      } else {
        setEmailError("");
      }

      // Validate Phone number
      if (!phone.match(/^\d{10,}$/)) {
        setPhoneError(i18n.t("plsEnterValidPhone"));
        hasError = true;
      } else {
        setPhoneError("");
      }

      // Validate Date
      if (!date) {
        setDateError(i18n.t("plsEnterValidAppointment"));
        hasError = true;
      } else {
        setDateError("");
      }

      if (!name || !phone || !email || !date) {
        setFormError(i18n.t("allFieldsIsRequired"));
        hasError = true;
      } else {
        setFormError("");
      }

      if (!hasError) {
        setPartiesData(data);
      }
    }
  };

  const increment = () => {
    setPersonCount(personCount + 1);
  };
  const decrement = () => {
    setPersonCount((prevCount) => {
      if (prevCount > 1) {
        return prevCount - 1;
      }
      return prevCount;
    });
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 21,
      width: "auto",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  useEffect(() => {
    document.title =i18n.t("PartiesApp");
    getPartiesData(userId);
  }, [userId]);

  const AddCountToData = (data) => {
    const newData = data.map((item) => {
      const newItem = { ...item };
      newItem.count = 1;
      newItem.subtotal = newItem.price * newItem.count;
      return newItem;
    });
    setMyData(newData);
    console.log(newData);
    setLoading(false);
  };

  const getPartiesData = (id) => {
    const url = process.env.REACT_APP_API_URL + get_parties + id;
    // const url = Main_Api_Link + get_parties + id;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        console.log(res.data.data.partyItems);
        AddCountToData(res.data.data.partyItems);
        // handleSubTotalChange()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setPartiesData = (data) => {
    const url = Main_Api_Link + store_parties;

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "post",
      url,
      headers,
      data,
    })
      .then((res) => {
        if (res.data.status === true) {
          MySwal.fire({
            icon: "success",
            title: i18n.t("OperationSucess"),
            text: i18n.t("PartySucess"),
          });
          setTimeout(() => {
            navigate(-1);
          }, 3000);
        } else {
          MySwal.fire({
            icon: "error",
            title: i18n.t("operationFailure"),
            text: i18n.t("CheckTheInfoPlease"),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="headerNav"></div>
      <div className="ReservationWrapper">
        <div className="form">
          <h3>{t("PartiesApp")}</h3>
          <Grid
            container
            spacing={2}
            width={{ md: 1000, sm: 500, xs: 300 }}
            style={{ paddingBottom: "4vw" }}
          >
            <Grid md={6} sm={12} xs={12}>
              <FormControl className="formcontrol" variant="standard">
                <TextField
                  id="name-input"
                  label={t("theName")}
                  variant="outlined"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                {nameError && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontWeight: "900" }}
                  >
                    {nameError}
                  </div>
                )}
              </FormControl>
              <br />
              <FormControl>
                <div className="telcontainer">
                  <span>{t("phoneNumber")}</span>
                  <PhoneInput
                    country={"sa"}
                    onlyCountries={["us", "eg", "sa", "ae"]}
                    inputClass={"intlTel"}
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
                {phoneError && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontWeight: "900" }}
                  >
                    {phoneError}
                  </div>
                )}
              </FormControl>
              <br />
              <br />

              <FormControl className="formcontrol" variant="standard">
                <TextField
                  id="name-input"
                  label={t("address")}
                  variant="outlined"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                />
                {addressError && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontWeight: "900" }}
                  >
                    {addressError}
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} sm={12} xs={12}>
              <FormControl
                className="formcontrol"
                variant="standard"
                style={{ marginBottom: "2.4vw" }}
              >
                <TextField
                  id="email-input"
                  label={t("email")}
                  variant="outlined"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {emailError && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontWeight: "900" }}
                  >
                    {emailError}
                  </div>
                )}
              </FormControl>
              <br />
              <FormControl className="formcontrol" variant="standard">
                <InputLabel
                  sx={{ fontFamily: "inherit" }}
                  shrink
                  htmlFor="date-input"
                >
                  {t("date")}
                </InputLabel>
                <BootstrapInput
                  id="date-input"
                  type="date"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
                {dateError && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontWeight: "900" }}
                  >
                    {dateError}
                  </div>
                )}
              </FormControl>
              <br />
              <FormControl className="formcontrol" variant="standard">
                <InputLabel
                  sx={{ fontFamily: "inherit" }}
                  shrink
                  htmlFor="date-input"
                >
                  {t("time")}
                </InputLabel>
                <TimePicker onChange={(e) => handleTime(e)} value={time} />

                {dateError && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontWeight: "900" }}
                  >
                    {dateError}
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid md={12} sm={12} xs={12}>
              <TableComponent
                data={myData}
                loading={loading}
                setData={setMyData}
                setTotal={setTotal}
              />
            </Grid>
          </Grid>
          <Grid
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row-reverse",
              marginTop: "20px",
            }}
          >
            <p style={{ fontWeight: "900", color: "#191970" }}>
                {t("numOfGuests")}
            </p>
            <div className="counter">
              <div>
                <Button
                  onClick={decrement}
                  sx={{ fontSize: 16 }}
                  variant="contained"
                >
                  -
                </Button>
              </div>
              <div className="countertxt">{personCount}</div>
              <div>
                <Button
                  onClick={increment}
                  sx={{ fontSize: 16 }}
                  variant="contained"
                >
                  +
                </Button>
              </div>
            </div>
          </Grid>
          {formError && (
            <div
              className="error-message"
              style={{ color: "red", fontWeight: "900", marginTop: "1rem" }}
            >
              {formError}
            </div>
          )}
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                fontSize: 16,
                width: { md: "20%", sm: "40%", xs: "60%" },
                borderRadius: 2,
                marginRight: 4,
              }}
              variant="contained"
              color="error"
              size="large"
            >
              {t("back")}
            </Button>
            <Button
              onClick={handleSubmit}
              sx={{
                fontSize: 16,
                width: { md: "20%", sm: "40%", xs: "60%" },
                borderRadius: 2,
              }}
              variant="contained"
            >
              {t("TableResrve")}
            </Button>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Parties;
