import { useRef, useState, useEffect } from "react";

const useButtonLoader = (defaultText = "Load", loadingText = "Loading...") => {
    const [isLoading, setLoading] = useState(false);
    const element = useRef(null);

    useEffect(() => {
        if (isLoading) {
            element.current.disabled = true;
            element.current.innerHTML =
                `${loadingText} <span class="spinner"></span>`;
        } else {
            element.current.disabled = false;
            element.current.innerHTML = defaultText;
        }
    },  [isLoading, defaultText, loadingText]);

    return [element, setLoading];
};

export default useButtonLoader;