import * as React from "react"
const Soya = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHUlEQVR4nN3UvyvGURTH8RfJJJIyGI0Wg8Vm8heI/8AgymhS/gD58QcYLAyKEqPBgIlBWJRJJgNPPUh+des8dXvC99vzLPKpW99zvve87zn3nC7/Va2YxQVu0NsMrAuH+MzWUjPA3YBsYjm+rxqFjQTgOPNd47lRYC2j1bA78Bi+9rKQFkzhHG8RfItx7IV9XxaWTt2va8B3a60scDELSvc2iZU6WAV9ZWDdeMlgbdm/o/BXMVoGNoztLIuUWa6z8E8XgTqxjo+6slJ3a5rJ/ANFDTjJNr/WQVOZp5m9VZTdXGxMg9qPHjz90NWDqOZXXcbmhbCH8J5B0iOwg4mYzUJVIvAhBrYadrrPeQ1oLF6RNPUpsztsYLAR2N/VF6RAZnTTUcZuAAAAAElFTkSuQmCC"
      width={20}
      height={20}
    />
  </svg>
)
export default Soya
