import * as React from "react"
const Milk = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJElEQVR4nL3UvyvFURjH8ZeSullsFrFcZTGQgSz8B34vlEVKNptY2QxMFoOSQWIls+EqikxW5juhROjo3NLte7/3UHzqGU59nvd5zjnPc/hDLWIX21jGFHrQiubfAG/wkRjHKcCBHwCfU6u8+gE0xCvusFQLuJYIusUkZrAZwbNZwJFE4H1V3hEOs4CdMaGEsxpxHj3Fb3kl7GQBx/CGlpx7bsIT5uJ6MG4wlGXewqX6CpXuoYBrnOb14kYCcAUPsR/LaM8yFeJxxxOAw/GYZfTVMnVFU3cCsC16R/NMxWgK81tPHdHbn2dqxCPmE4ATeKnTDV/axwUa5Osk9YPojWO0kOOZxnt8GClaja8dKjioijBiYcP1VFhFocJqWCXCR/x/+gStQXQwq2uc6AAAAABJRU5ErkJggg=="
      width={20}
      height={20}
    />
  </svg>
)
export default Milk
