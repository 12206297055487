import React from 'react'
import { Link } from 'react-router-dom'
import "./index.scss"
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t, i18n } = useTranslation();
 
  return (
    <div className='footerWrapper'>
        <p className='footerTxt'>© 2023 Talab Hub.</p>
        <Link  to={"https://talabhub.com/"} target="blank">{t("talabhubWorks")}</Link>
    </div>
  )
}

export default Footer