import * as React from "react"
const Onion = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeklEQVR4nL3TS0uWURQF4IfCLlZUQo5tmKMkpwVlN0diN2niIASp/5AhQfQDuhAEkfYLUhJRRAz7A2XNIgkcV1ADFY0DS/iQvs/Pd9CCBefss9/17rXPPvxnXMe1mv1AWBkf8LRm/wyLVcW6sYE+HEIr+hM7s1uxg1hKNXswi5msF3NWcppGsfkDHejFZnglsXL2pFmxq7E1iKNYxli4nNhgci7vJFYsfcF09q+wgrZwJTHJ+Zxv6uJirJ3CpVRRRqcH53EjsZLXmdyeRoKP8DHrhVzGAXzDV+zDHOaT8ynf1MU43mb9B3dT6dallMru4XdyJtLbunhR8/fvGEV7+lr6dQIPczlbLp43EhzK3w/jccbjdM15V2L3cSQu7jQSPI5fGMngln6t4n24Gpstqf4njtkBpW9rudG9uI2X4c3k3MI6hjWJ0YxHafjZvOXShnN4k7MHdokLebOb27iUmayEsX8Ivq4qVlBsTWEyfFfF6nacxP6wrBviL+ZTY35clj1yAAAAAElFTkSuQmCC"
      width={20}
      height={20}
    />
  </svg>
)
export default Onion
