import * as React from "react"
const Glutin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMUlEQVR4nN3UvSuGURjH8Q/KgkiUxcsgZTAoRcpLSR5l8lpkU3aTwWKzGf0DJv4A+QeUlM1CSUmhDFJSXjt1PXVPPPdj4lenzrnu+/yu73Wd+9z8R1WgOuYrOMZtuWYDuMASFvEZ46Qcszo8hMEcdjOGq3mMqjCO0YxBOyawg5m8ZNt4QS/esRfx9iBdzvS0JLqnoBpCAc3Yz9Cex0F9q/TCCGoyG9M6aTJIU+we/aWQbeA1aE6Doj4oB9GDYdSWWupdEKQSG9GNywztcxj+WGYh+vYWG6fiWQvOIpZ6uoXK7+1Yxwc6cISbmC9gGm3xLaaEJekqCGbRhL5oerHMlGxMDj3FxvlYN+Aw+pWSrcmpg7jkrUGZSu3yC9WhM1N6caQfQdmqjlvwiGts5jmEv6Ev9uZK7sFiR7IAAAAASUVORK5CYII="
      width={20}
      height={20}
    />
  </svg>
)
export default Glutin
