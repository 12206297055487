import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";
import { persistor, store } from "./features/store";
import { PersistGate } from "redux-persist/integration/react";
import LandingPage from "./views/LandingPage/LandingPage";
import MenuPage from "./views/MenuPage/MenuPage";
import Reservation from "./views/MenuPage/Reservation/Reservation";
import Rating from "./views/MenuPage/Rating/Rating";
import Review from "./views/MenuPage/Rating/Review";
import "./App.scss";
import CartCheckout from "./views/MenuPage/Cart/CartCheckout";
import Parties from "./views/LandingPage/Parties";
import ResturantReviews from "./views/MenuPage/Reviews/ResturantReviews";
import ErrorPage from "./components/ErrorPage/ErrorPage";
function App() {
  const THEME = createTheme({
    typography: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    button: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    },
  });
  return (
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="/:userId" element={<LandingPage />} />
            <Route path="/menu/:userId" element={<MenuPage />} />
            <Route path="/tableReservation/create/:restaurantId" element={<Reservation />} />
            <Route path="/rating/create/:restaurantId" element={<Rating />} />
            <Route path="/review/create/:restaurantId" element={<Review />} />
            <Route path="/cart-checkout/:userId" element={<CartCheckout />} />
            <Route path="/parties/:userId" element={<Parties />} />
            <Route path="/restaurantReviews/:userId" element={<ResturantReviews />} />
            <Route path="*" element={<ErrorPage />} />

            {/* for branches */}
            <Route path="/menu/:userId/:branchId" element={<MenuPage />} />
            <Route path="/tableReservation/create/:restaurantId/:branchId" element={<Reservation />} />
            <Route path="/rating/create/:restaurantId/:branchId" element={<Rating />} />
            <Route path="/review/create/:restaurantId/:branchId" element={<Review />} />
            <Route path="/cart-checkout/:userId/:branchId" element={<CartCheckout />} />
         </Routes>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
