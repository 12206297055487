import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import Grid from "@mui/material/Unstable_Grid2";
import "./index.scss";
import Button from "@mui/material/Button";
import Footer from "../../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { Box, Checkbox, Divider, FormGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
// import IntlTelInput from "react-intl-tel-input";
import PhoneInput from "react-phone-input-2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useDispatch, useSelector } from "react-redux";
import {
  DecrementItemCount,
  IncrementItemCount,
  removeItem,
} from "../../../features/cartSlice";
import { Main_Api_Link, tables } from "../../../constants/ApiConstants";
import axios from "axios";
import Loader1 from "../../../components/Loader1";
import { images } from "../../../assets";
import { useTranslation } from "react-i18next";

//! for demo purposes only

const getRandomMenuImage = () => {
  const keys = Object.keys(images);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return images[randomKey];
};

//? for demo purposes only

const CartCheckout = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const randomImg = getRandomMenuImage();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    document.title = "Check Out";
    getTableitems();
  }, []);

  let { userId } = useParams();
  const cartItems = useSelector((state) => state.persistedReducer.cardItems);
  const Restitle = useSelector((state) => state?.dataReducer?.data?.title);
  const [dense, setDense] = useState(false);
  const [otherPrice, setOtherPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [table, setTable] = useState([]);
  const [tableId, setTableId] = useState("");
  const handleChange = (event) => {
    setTableId(event.target.value);
  };
  const getTotalPrice = (items) => {
    return items.reduce((total, item) => total + item.count * item.price, 0);
  };

  const handleIncrementNumber = (id, price, number) => {
    dispatch(IncrementItemCount({ id, price, newCount: number + 1 }));
  };

  const handleMinusItem = (id, price, number) => {
    dispatch(DecrementItemCount({ id, price, newCount: number - 1 }));
  };

  const handleDeleteItem = (id) => {
    dispatch(removeItem(id));
  };

  const getTableitems = useCallback(() => {
    const url = process.env.REACT_APP_API_URL + tables + userId;
    // const url = Main_Api_Link + tables + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "get",
      url,
      headers,
    })
      .then((res) => {
        setTable(res.data.tables);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);
  // old code
  // const handleCheckout = ()=>{
  //   const url = `http://talabhub.test/${Restitle}/cart-checkout`
  //   // // const url = process.env.REACT_APP_API_URL + tables + "cart-checkout";
  //   // // const url = Main_Api_Link + tables + userId;

  //   const body= {
  //     "country":null,
  //     "cardType":null,
  //     "paymentType":"tap",
  //     "timeslot":"1410_1440",
  //     "deliveryCost":"0",
  //     "comment":null,
  //     "coupon_code":null
  //  }
  //   const headers = {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //     Accept: "application/json",
  //   };
  //   axios({
  //     method: "post",
  //     body,
  //     url,
  //     headers,
  //   })
  //     .then((res) => {
  //       setTable(res.data.tables);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const calculateOrderPrice = (cartItems) => {
    // You need to define the logic for calculating the order price based on cart items
    // For example, summing the prices of all items in the cart
    return cartItems.reduce((total, item) => total + item.price * item.count, 0);
  };


  const handleCheckout = () => {
    const url = process.env.REACT_APP_API_URL + "/payment";
    const items = cartItems.map((item, index) => ({
      id: index + 1, // Assuming each item needs a unique identifier
      qty: item.count,
      extrasSelected: [], // You need to define how to extract extras selected for each item
      variant: 1, // You need to define how to determine the variant for each item
    }));

    const body = {
      restaurant_id:userId,
      vendor_id:userId,
      delivery_method: "delivery",
      payment_method: "cod",
      address_id: 1,
      platform: "WebService",
      items:items,
      order_price:calculateOrderPrice(cartItems),
      comment: "",
      timeslot: "1320_1350",
      stripe_token: null,
    };

    // console.log(JSON.stringify(body))


    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    axios({
      method: "post",
      data:body,
      url,
      headers,
    })
      .then((res) => {
        const paymentUrl = res?.data?.url;
        if (paymentUrl) {
          const absoluteUrl = paymentUrl.startsWith("http") ? paymentUrl : `http://${paymentUrl}`;
          window.open(absoluteUrl, "_blank"); // Open in a new tab
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Navbar />
      <div className="headerNav"></div>
      <div className="CartWrapper">
        <Grid container spacing={4}>
          {/* left section */}
          <Grid xs={12} sm={12} md={7} lg={7} xl={7}>
            {/* first Section */}
            <Box className="form">
              <Box className="titleContainer">
                <h3>{t("types")}</h3>
              </Box>
              <div className="cartItemsWrapper">
                {cartItems.map((item) => {
                  const options = item.variants[0]?.options || {}; // Add a conditional check
                  const enValue = Object.values(options)[0]?.en || ""; // Access the 'en' value dynamically
                  const enValue2 = Object.values(options)[1]?.en || ""; // Access the 'en' value 2 dynamically
                  const extra1 = item.extraName?.[0] || "";
                  const extra2 = item.extraName?.[1] || "";
                  return (
                    <Box key={item.id} className="itemsMainWrapper">
                      <Box className="SideImageContainer">
                        <img
                          // src={item.src}
                          src={randomImg}
                          alt={item.name}
                          className="SideImageContainerImg"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://www.gifpng.com/100x100/333333/ffffff";
                          }}
                        />
                      </Box>
                      <Box className="TextContainer">
                        <p>
                          {item.name}
                          {item.activeOption === true
                            ? ` - ${enValue} - ${enValue2}`
                            : null}
                          {item.activeExtra === true
                            ? ` - ${extra1} - ${extra2}`
                            : null}
                        </p>
                        <p>
                          {item.count} x {t("ryal")} {item.price}
                        </p>
                        <Box className="Buttonsontainer">
                          <IconButton
                            onClick={() =>
                              handleMinusItem(item.id, item.price, item.count)
                            }
                            aria-label="remove"
                            size="small"
                            sx={{ border: "1px solid #8898aa", marginRight: 2 }}
                          >
                            <RemoveIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleIncrementNumber(
                                item.id,
                                item.price,
                                item.count
                              )
                            }
                            aria-label="add"
                            size="small"
                            sx={{ border: "1px solid #8898aa", marginRight: 2 }}
                          >
                            <AddIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{ border: "1px solid #8898aa", marginRight: 2 }}
                            onClick={() =>
                              handleDeleteItem(item.id, item.price, item.count)
                            }
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {cartItems.length === 0 && (
                  <p className="text-center">{t("YourCartIsEmpty")}</p>
                )}
              </div>
            </Box>
            {/* Second Section */}
            <Box className="form" sx={{ marginTop: 3 }}>
              <Box className="titleContainer">
                <h3>{t("reciveingWay")}</h3>
              </Box>
              <Box className="itemsMainWrapper">
                <RadioGroup
                  className="RadioInputContainer"
                  aria-labelledby="radio-buttons"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="محلى"
                    control={<Radio />}
                    label={t("local")}
                  />
                  <FormControlLabel
                    value="خارجى \ سفرى"
                    control={<Radio />}
                    label={t("outside")}
                  />
                </RadioGroup>
              </Box>
            </Box>
            {/* Third Section */}
            <Box className="form" sx={{ marginTop: 3 }}>
              <Box className="titleContainer">
                <h3>{t("table")}</h3>
              </Box>
              {}
              <Box className="itemsMainWrapper">
                {loading === true ? (
                  <Loader1 />
                ) : (
                  <>
                    <FormControl fullWidth className="selectContainer">
                      <InputLabel id="demo-simple-select-label">
                        {t("pickTable")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tableId}
                        label={t("pickTable")}
                        onChange={handleChange}
                      >
                        {table.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.restoarea_id}
                            size={item.size}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Box>
            </Box>
            {/* forth Section */}
            <Box className="form" sx={{ marginTop: 3 }}>
              <Box className="titleContainer">
                <h3>{t("personalInfo")}</h3>
              </Box>
              <Box className="itemsMainWrapper">
                <FormControl fullWidth className="InputContainer">
                  <label style={{ fontSize: 13 }} htmlFor="name">
                    {t("fullName")}
                  </label>
                  <TextField
                    sx={{
                      marginTop: 1,
                      marginBottom: 3,
                      fontFamily:
                        '-apple-system,"BlinkMacSystemFont","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    }}
                    id="outlined-basic"
                    variant="outlined"
                  />
                  <div className="telcontainer">
                    <span>{t("phoneNumber")}</span>
                    {/* <IntlTelInput inputClassName="intlTel" /> */}
                    <PhoneInput
                      country={"sa"}
                      onlyCountries={["us", "eg", "sa", "ae"]}
                      inputClass={"intlTel"}
                    />
                  </div>
                </FormControl>
              </Box>
            </Box>
            {/* fifth Section */}
            <Box className="form" sx={{ marginTop: 3 }}>
              <Box className="titleContainer">
                <h3>{t("notes")}</h3>
              </Box>
              <Box className="itemsMainWrapper">
                <FormControl fullWidth className="InputContainer">
                  <TextField
                    placeholder={t("writeAnyNotesHere")}
                    sx={{
                      fontFamily:
                        '-apple-system,"BlinkMacSystemFont","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    }}
                    multiline
                    rows={2}
                    maxRows={4}
                  />
                </FormControl>
              </Box>
            </Box>
          </Grid>
          {/* right section */}
          <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
            {/* sixth Section */}
            <Box className="form">
              <Box className="titleContainer">
                <h3>{t("doYouHaveVouture")}</h3>
              </Box>
              <Box className="itemsMainWrapper">
                <FormControl fullWidth className="InputContainer">
                  <TextField
                    sx={{
                      marginTop: 1,
                      marginBottom: 3,
                      fontFamily: "inherit",
                    }}
                    id="outlined-basic"
                    label={t("fullName")}
                    variant="outlined"
                  />
                  <Box className="inputButtonContainer">
                    <p>{t("onlyOneVouture")}</p>
                    <Box>
                      <Button
                        sx={{
                          width: 100,
                          fontSize: 16,
                          fontFamily: "inherit",
                          fontWeight: 400,
                        }}
                        variant="contained"
                      >
                        {t("acceptance")}
                      </Button>
                    </Box>
                  </Box>
                </FormControl>
              </Box>
            </Box>
            {/* seventh Section */}
            <Box className="form" sx={{ marginTop: 3 }}>
              <Box className="titleContainer">
                <h3>{t("acceptOrder")}</h3>
              </Box>
              <Box className="listMainWrapper">
                <Box className="listMainWrapperMainTxt">
                  <p>{t("appolgyText")}</p>
                </Box>
                <Grid item xs={10} md={10} textAlign={"center"} margin={"auto"}>
                  <List dense={dense} className="listMainWrapperMainList">
                    <Divider />
                    <ListItem className="ListItem">
                      <p>{t("subTotal")}</p>
                      <p>
                        {getTotalPrice(cartItems).toFixed(2)} {t("ryal")}
                      </p>
                    </ListItem>
                    <Divider />
                    <ListItem className="ListItem">
                      <p>{t("taxes")}</p>
                      <p>
                        {otherPrice} {t("ryal")}
                      </p>
                    </ListItem>
                    <Divider />
                    <ListItem className="ListItem">
                      <p className="ListItemLastTxt">{t("total")}</p>
                      <p>
                        {Number(getTotalPrice(cartItems).toFixed(2)) +
                          otherPrice}{" "}
                        {t("ryal")}
                      </p>
                    </ListItem>
                  </List>
                  <FormGroup className="CheckBoxContainer">
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={t("acceptText")}
                    />
                  </FormGroup>
                  <Box marginTop={4}>
                    <Button onClick={handleCheckout} variant="contained">
                      {t("completeOrder")}
                    </Button>
                    <Button
                      onClick={() => navigate(-1)}
                      sx={{ marginLeft: 5 }}
                      variant="outlined"
                      color="error"
                    >
                      {t("cancelOrder")}
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default CartCheckout;
