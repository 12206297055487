import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Modal from "@mui/material/Modal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PeopleIcon from "@mui/icons-material/People";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Twitter from "../../components/Svg/Twitter/Twitter";
import Instagram from "../../components/Svg/Instagram/Instagram";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import CardMedia from "@mui/material/CardMedia";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  IncrementItemCount,
  DecrementItemCount,
  removeItem,
} from "../../features/cartSlice";
import { images } from "../../assets";
import { useTranslation } from "react-i18next";





//! for demo purposes only

const getRandomMenuImage = () => {
  const keys = Object.keys(images);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return images[randomKey];
};

//? for demo purposes only

function Navbar({ logo, id }) {
const randomImg = getRandomMenuImage()
const { t, i18n } = useTranslation();
const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};


  const dispatch = useDispatch();
  const [openWorkingHoursModal, setOpenWorkingHoursModal] = useState(false);
  const handleOpenWorkingHoursModal = () => setOpenWorkingHoursModal(true);
  const handleCloseWorkingHoursModal = () => setOpenWorkingHoursModal(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const handleOpenHelpModal = () => setOpenHelpModal(true);
  const handleCloseHelpModal = () => setOpenHelpModal(false);
  const [openSocialModal, setOpenSocialModal] = useState(false);
  const handleOpenSocialModal = () => setOpenSocialModal(true);
  const handleCloseSocialModal = () => setOpenSocialModal(false);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [tableNum, setTableNum] = useState("");
  const [imageError1, setImageError1] = useState(false);
  const [imageError2, setImageError2] = useState(false);
  const [imageError3, setImageError3] = useState(false);
  const handleImageError1 = () => {
    setImageError1(true);
  };
  const handleImageError2 = () => {
    setImageError2(true);
  };
  const handleImageError3 = () => {
    setImageError3(true);
  };
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const [data, setData] = useState({
    workingHours: [
      {
        id: 1,
        day: "الاثنين",
        hours: "12:00 PM - 11:50 PM",
      },
      {
        id: 2,
        day: "الثلاثاء",
        hours: "12:00 PM - 11:50 PM",
      },
      {
        id: 3,
        day: "الأربعاء",
        hours: "12:00 PM - 11:50 PM",
      },
      {
        id: 4,
        day: "الخميس",
        hours: "12:00 PM - 1:50 AM",
      },
      {
        id: 5,
        day: "الجمعة",
        hours: "12:00 PM - 1:50 AM",
      },
      {
        id: 6,
        day: "السبت",
        hours: "12:00 PM - 11:50 PM",
      },
      {
        id: 7,
        day: "الأحد",
        hours: "12:00 PM - 11:50 PM",
      },
    ],
    tables: [
      {
        id: 11,
        name: "الجلسات الداخليه - طاوله 1",
      },
      {
        id: 12,
        name: "الجلسات الداخليه - طاوله 2",
      },
      {
        id: 13,
        name: "داخلي - طاوله 1",
      },
      {
        id: 14,
        name: "داخلي - طاوله 2",
      },
      {
        id: 15,
        name: "داخلي - طاوله 3",
      },
    ],
  });
  const CustomButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    width: "100%",
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#24613e",
    borderColor: "#24613e",
    fontFamily: "Roboto",
    "&:hover": {
      backgroundColor: "#205737",
      borderColor: "#205737",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#205737",
      borderColor: "#205737",
      boxShadow: "none",
    },
  });
  const Userdata = useSelector((state) => state.dataReducer.data);
  const isBranch = useSelector((state) => state.dataReducer.isBranch);
  const branchId = useSelector((state) => state.dataReducer.branchId);
  const cartItems = useSelector((state) => state.persistedReducer.cardItems);
  const landingData = useSelector((state) => state.dataReducer.landingData);
  const userId = useSelector((state) => state.dataReducer.userId);
  const toggleDrawerRedux = useSelector(
    (state) => state.dataReducer.toggleDrawer
  );

  const getTotalPrice = (items) => {
    return items.reduce((total, item) => total + item.count * item.price, 0);
  };

  const handleChange = (event) => {
    setTableNum(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   if (toggleDrawerRedux === true) {
  //     // toggleDrawer("right", true);
  //     console.log("Toggle drawer redux should be enabled")
  //   } else {
  //     console.log("Toggle drawer redux should be disabled")
  //     // toggleDrawer("right", false);
  //   }
  // }, [toggleDrawerRedux]);

  const handleScroll = () => {
    if (window.pageYOffset > 150) {
      setBackgroundColor("#f6f3f2");
    } else {
      setBackgroundColor("transparent");
    }
  };

  function isUrlValid(url) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleIncrementNumber = (id, price, number) => {
    dispatch(IncrementItemCount({ id, price, newCount: number + 1 }));
  };

  const handleMinusItem = (id, price, number) => {
    dispatch(DecrementItemCount({ id, price, newCount: number - 1 }));
  };

  const handleDeleteItem = (id) => {
    dispatch(removeItem(id));
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 450 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box padding={5}>
        <h5>{t("cart")}</h5>
        {/* cart items here */}
        {cartItems.length > 0 ? (
  <>
    {cartItems.map((item) => {
      let extra1 = "";
      let extra2 = "";
      let options = {};

      if (item.extraName) {
        extra1 = item.extraName[0] || "";
        extra2 = item.extraName[1] || "";
      }

      if (item.variants && item.variants.length > 0 && item.variants[0].options) {
        options = item.variants[0].options;
      }

    const enValue = Object.values(options)[0]?.en || "";
      const enValue2 = Object.values(options)[1]?.en || "";

      return (
        <Box className={"cardItem"} key={item.name}>
          <img
            className={"cardItemImg"}
             src={item.src}
            // src={randomImg}
            alt={item.name}
            width={150}
          />
          <Box className={"cardItemtexts"}>
            <p className="mainname">
              {item.name}
              {item.activeOption === true ? ` - ${enValue} - ${enValue2}` : null}
              {item.activeExtra === true ? ` - ${extra1} - ${extra2}` : null}
            </p>
            <p className="price">
              {item.count} x {item.price}
            </p>
            <IconButton
              color="black"
              aria-label="upload picture"
              component="label"
              onClick={() =>
                handleIncrementNumber(item.id, item.price, item.count)
              }
            >
              <AddIcon />
            </IconButton>
            <IconButton
              color="black"
              aria-label="upload picture"
              component="label"
              onClick={() =>
                handleMinusItem(item.id, item.price, item.count)
              }
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              color="black"
              aria-label="upload picture"
              component="label"
              onClick={() =>
                handleDeleteItem(item.id, item.price, item.count)
              }
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      );
    })}
    <p className="totalPrice">
      {t("totalPrice")} {getTotalPrice(cartItems).toFixed(2)} {t("ryal")}
    </p>
    <Link
      className="dynamicLink"
      to={
        isBranch === true
          ? `/cart-checkout/${userId}/${branchId}`
          : `/cart-checkout/${userId}`
      }
      state={{ data: data }}
      sx={{ textDecoration: "none" }}
    >
      <CustomButton
        className="btn1"
        variant="contained"
        sx={{
          marginTop: 2,
          padding: 1,
          fontSize: 18,
          fontFamily: "Roboto",
        }}
      >
        {t("confirmOrder")}
      </CustomButton>
    </Link>
  </>
) : (
  <p className="emptyCart">{t("EmptyCart")}</p>
)}

      </Box>
    </Box>
  );

  const list2 = (anchor) => (
    <Box
      sx={{
        height: 700,
        width: anchor === "top" || anchor === "bottom" ? "auto" : 450,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box padding={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {
            <>
              {isUrlValid(Userdata.logo) === true ? (
                <Link>
                  <CardMedia
                    className="mainLogoWide"
                    component="img"
                    alt={"aaa"}
                    height={80}
                    onError={handleImageError1}
                    image={
                      imageError1
                        ? "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                        : Userdata.logo
                    }
                    src={
                      imageError1
                        ? "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                        : Userdata.logo
                    }
                  />
                </Link>
              ) : (
                <>
                  <Link>
                    <img
                      className="mainLogoWide"
                      src={
                        "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                      }
                      alt="aaa"
                      height={80}
                    />
                  </Link>
                </>
              )}
            </>
          }
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            className="navBox2"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/* <ListBtn
              onClick={handleOpenHelpModal}
              sx={{ height: 40, my: 3 }}
              startIcon={<NotificationsIcon />}
            >
              <Link
                state={{ data: data }}
                to={`/help/waiter/${Userdata.id}`}
                sx={{ textDecoration: "none" }}
              >
                طلب مساعدة النادل
              </Link>
            </ListBtn> */}
            {/* <ListBtn
              onClick={handleOpenWorkingHoursModal}
              sx={{ height: 40, my: 3 }}
              startIcon={<CalendarMonthIcon />}
            >
              <Link
                to={`/register/visit/${Userdata.id}`}
                sx={{ textDecoration: "none" }}
              >
                تسجيل الزيارة
              </Link>
            </ListBtn> */}
            <ListBtn
              onClick={handleOpenWorkingHoursModal}
              sx={{ height: 40, my: 3 }}
              startIcon={<EditIcon />}
            >
              <Link
                to={
                  isBranch === true
                    ? `/rating/create/${userId}/${branchId}`
                    : `/rating/create/${userId}`
                }
                sx={{ textDecoration: "none" }}
              >
                {t("rate")}
              </Link>
            </ListBtn>
            <ListBtn
              onClick={handleOpenSocialModal}
              sx={{ height: 40, my: 3 }}
              startIcon={<PeopleIcon />}
            >
              {t("socialMediaPages")}
            </ListBtn>
          </Box>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <ListBtn
            onClick={handleOpenWorkingHoursModal}
            sx={{ height: 40, my: 3, color: "black" }}
            startIcon={<AccessTimeFilledIcon />}
          >
            {t("WorkingHours")}
          </ListBtn>
        </Box>
      </Box>
    </Box>
  );

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const ListBtn = styled(Button)({
    boxShadow: "none",
    fontWeight: "bold  ",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#fff",
    borderColor: "#fff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#fff",
      boxShadow: "none",
      color: "#555",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#fff",
      color: "#555",
    },
  });

  return (
    <AppBar
      component={"nav"}
      style={{ background: "inherit", boxShadow: "none", backgroundColor }}
    >
      <Container maxWidth="xl">
        {/* workingHours Modal */}
        <Modal
          open={openWorkingHoursModal}
          onClose={handleCloseWorkingHoursModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h3 className="locationHeaderCenter">{t("WorkingHours2")} </h3>
            <hr />
            {landingData?.workingHours?.map((work) => (
              <div key={work.id} className="LocationModalLine">
                <p>{work.hours}</p>
                <p>: {work.day}</p>
              </div>
            ))}
          </Box>
        </Modal>
        {/* HelpOrder Modal */}
        <Modal
          open={openHelpModal}
          onClose={handleCloseHelpModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h5 className="locationHeaderCenter">{t("callForHelp")} </h5>
            <hr />
            <p>{t("table")}</p>
            <div className="startForm">
              <FormControl
                sx={{ marginTop: 2, minWidth: 300, borderRadius: 30 }}
              >
                <InputLabel>{t("table")}</InputLabel>
                <Select
                  sx={{ borderRadius: 30, height: 50 }}
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={tableNum}
                  label={t("lang")}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>{t("ChooseTable")}</em>
                  </MenuItem>
                  {data?.tables.map((table) => (
                    <MenuItem key={table.id} value={table.name}>
                      {table.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button sx={{ marginTop: 4 }} variant="contained">
                {t("order")}
              </Button>
            </div>
          </Box>
        </Modal>
        {/* HelpOrder Modal */}
        <Modal
          open={openSocialModal}
          onClose={handleCloseSocialModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h5 className="locationHeaderCenter">{t("socialMediaPages")} </h5>

            <div className="socialBtns2">
              <div
                onClick={() =>
                  window.open(
                    `https://twitter.com/${data?.twitterAccountLink}`,
                    "_blank"
                  )
                }
                className="icon3"
                style={{ color: data?.mainColor }}
              >
                <Twitter />
              </div>
              <div
                onClick={() =>
                  window.open(
                    `https://www.instagram.com/${data?.InstagramAccountLink}/`,
                    "_blank"
                  )
                }
                className="icon3"
                style={{ color: data?.mainColor }}
              >
                <Instagram />
              </div>
            </div>
          </Box>
        </Modal>
        <Toolbar>
          {/* // ? for wide screens */}
          {/* wide screen logo */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {data.name}
          </Typography> */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {
              <>
                {isUrlValid(logo) === true ? (
                  <Link
                    to={
                      isBranch === true
                        ? `/menu/${branchId}`
                        : `/menu/${Userdata.id}`
                    }
                  >
                    <CardMedia
                      className="mainLogoWide"
                      component="img"
                      alt={"bigLogo"}
                      image={
                        imageError2
                          ? "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                          : Userdata.logo
                      }
                      onError={handleImageError2}
                      src={
                        imageError2
                          ? "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                          : Userdata.logo
                      }
                    />
                  </Link>
                ) : (
                  <>
                    <Link
                      to={
                        isBranch === true
                          ? `/menu/${userId}/${branchId}`
                          : `/menu/${userId}`
                      }
                    >
                      <img
                        className="mainLogoWide"
                        src={
                          "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                        }
                        alt="bigLogo"
                      />
                    </Link>
                  </>
                )}
              </>
            }
          </Box>
          {/* wide screen menu */}
          <Box
            className="NavBox"
            sx={{
              flexGrow: 0,
              marginLeft: "auto",
              display: { xs: "none", md: "flex" },
              backgroundColor,
            }}
          >
            <Link
              state={{ data: data }}
              to={
                isBranch === true
                  ? `/tableReservation/create/${userId}/${branchId}`
                  : `/tableReservation/create/${userId}`
              }
              onClick={handleCloseNavMenu}
              sx={{ textDecoration: "none" }}
            >
              <ListBtn sx={{ height: 40, my: 3 }}>
                <CalendarMonthIcon sx={{ mr: 1 }} />
                {t("TableResrve")}
              </ListBtn>
            </Link>
            <Link
              state={{ data: data }}
              to={
                isBranch === true
                  ? `/rating/create/${userId}/${branchId}`
                  : `/rating/create/${userId}`
              }
              onClick={handleCloseNavMenu}
              sx={{ textDecoration: "none" }}
            >
              <ListBtn sx={{ height: 40, my: 3 }}>
                <EditIcon sx={{ mr: 1 }} />
                {t("rate")}
              </ListBtn>
            </Link>
            <ListBtn
              onClick={handleOpenWorkingHoursModal}
              sx={{ height: 40, my: 3 }}
              startIcon={<AccessTimeFilledIcon />}
            >
              {t("WorkingHours2")}
            </ListBtn>
            <ListBtn
              onClick={toggleDrawer("right", true)}
              sx={{ height: 40, my: 3 }}
              startIcon={<ShoppingCartIcon />}
            >
              {t("cart")}
            </ListBtn>
            <React.Fragment key={"right"}>
              <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
              >
                {list("right")}
              </Drawer>
            </React.Fragment>
          </Box>
          {/*  // ? for small screens */}
          {/* small screen menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              onClick={toggleDrawer("top", true)}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={"top"}
              open={state["top"]}
              onClose={toggleDrawer("top", false)}
            >
              {list2("top")}
            </Drawer>
          </Box>
          {/* small screen logo */}

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              mr: 3,
              height: 100,
            }}
          >
            {
              <>
                {isUrlValid(Userdata.logo) === true ? (
                  <Link
                    to={
                      isBranch === true
                        ? `/menu/${userId}/${branchId}`
                        : `/menu/${userId}`
                    }
                  >
                    <CardMedia
                      className="mainLogosm"
                      component="img"
                      alt={"bigLogo"}
                      onError={handleImageError3}
                      image={
                        imageError3
                          ? "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                          : Userdata.logo
                      }
                      src={
                        imageError3
                          ? "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                          : Userdata.logo
                      }
                    />
                  </Link>
                ) : (
                  <>
                    <Link
                      to={
                        isBranch === true
                          ? `/menu/${userId}/${branchId}`
                          : `/menu/${userId}`
                      }
                    >
                      <img
                        className="mainLogosm"
                        src={
                          "https://app.talabhub.com/uploads/settings/81d126e0-f09b-4a6f-81e3-faf3eec315e1_large.jpg"
                        }
                        alt="bigLogo"
                      />
                    </Link>
                  </>
                )}
              </>
            }
            {/* <img src={data?.img} alt="MainLogo" className="mainLogosm" /> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
