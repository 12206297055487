import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardItems: [],
};

const cardSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.cardItems.push(action.payload.payload);
    },
    removeItem: (state, action) => {
      const idToRemove = action.payload;
      state.cardItems = state.cardItems.filter((item) => item.id !== idToRemove);
    },
    clearCart(state) {
      state.cardItems = [];
    },
    IncrementItemCount: (state, action) => {
      const { id, price, newCount } = action.payload;
      const itemToUpdateIndex = state.cardItems.findIndex(
        (item) => item.id === id && item.price === price
      );
      if (itemToUpdateIndex !== -1) {
        const updatedItem = { ...state.cardItems[itemToUpdateIndex], count: newCount };
        const updatedItems = [
          ...state.cardItems.slice(0, itemToUpdateIndex),
          updatedItem,
          ...state.cardItems.slice(itemToUpdateIndex + 1),
        ];
        state.cardItems = updatedItems;
      }
    },
    DecrementItemCount: (state, action) => {
      const { id, price, newCount } = action.payload;
      const itemToUpdateIndex = state.cardItems.findIndex(
        (item) => item.id === id && item.price === price
      );
      if (itemToUpdateIndex !== -1) {
        let updatedItems;
        if (newCount <= 0) {
          updatedItems = [
            ...state.cardItems.slice(0, itemToUpdateIndex),
            ...state.cardItems.slice(itemToUpdateIndex + 1),
          ];
        } else {
          const updatedItem = { ...state.cardItems[itemToUpdateIndex], count: newCount };
          updatedItems = [
            ...state.cardItems.slice(0, itemToUpdateIndex),
            updatedItem,
            ...state.cardItems.slice(itemToUpdateIndex + 1),
          ];
        }
        state.cardItems = updatedItems;
      }
    }
  },
});

export const { addToCart, removeItem, clearCart, IncrementItemCount , DecrementItemCount } =
  cardSlice.actions;
export default cardSlice.reducer;
